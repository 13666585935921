import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
    updateUserInfo,
    createUserInfo,
    getUserInfoDataById
} from "../../../../services/clinicPortalServices/userInfoService";
import toastr from "toastr";
import { rolesUserInfo } from "../../../../services/common/optionsData";

export default class UserInfoDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            userInfoId:
                this.props && this.props.userInfoId
                    ? this.props.userInfoId
                    : "",
            title: "",
            loom_embed_code: "",
            google_drive_link: "",
            role: "",
            errors: []
        };
    }

    componentDidMount() {
        if (this.state.userInfoId !== "") {
            this.loadDetails();
        }
    }

    loadDetails = () => {
        getUserInfoDataById(this.state.userInfoId).then((res) => {
            this.setState({
                title: res.data.title,
                loom_embed_code: res.data.loom_embed_code,
                google_drive_link: res.data.google_drive_link,
                role: res.data.role
            });
        });
    };

    handleClose = () => {
        this.setState({
            show: false
        });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    saveChanges = () => {
        let errors = [];

        if (!this.state.title) {
            errors.push("title");
        }
        if (!this.state.loom_embed_code && !this.state.google_drive_link) {
            errors.push("no_info");
        }
        if (!this.state.role) {
            errors.push("role");
        }

        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let userInfoInfo = {
            _id: this.state.userInfoId,
            title: this.state.title,
            loom_embed_code: this.state.loom_embed_code,
            google_drive_link: this.state.google_drive_link,
            role: this.state.role
        };
        if (this.state.userInfoId) {
            updateUserInfo(userInfoInfo).then((response) => {
                if (response.RESULT === "ERROR") {
                    toastr.warning(response.message);
                } else {
                    this.props.context.componentParent.loadGridData();
                    this.props.handleClose();
                }
            });
        } else {
            delete userInfoInfo._id;
            createUserInfo(userInfoInfo).then((response) => {
                if (response.RESULT === "ERROR") {
                    toastr.warning(response.message);
                } else {
                    this.props.context.componentParent.loadGridData();
                    this.props.handleClose();
                }
            });
        }
    };

    render() {
        return (
            <div>
                <form>
                    <div className="row form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Title{" "}
                                    <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("title")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("title")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Google Drive Link{" "}
                                    <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="google_drive_link"
                                    value={this.state.google_drive_link}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("no_info")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                <div
                                    className={
                                        this.hasError("no_info")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;Please fill loom video embed code
                                        or google drive link
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Loom Video Embed Code{" "}
                                    <span className="text-danger"> *</span>{" "}
                                </label>
                                <input
                                    type="text"
                                    name="loom_embed_code"
                                    value={this.state.loom_embed_code}
                                    onChange={this.handleChange}
                                    required
                                    className={
                                        this.hasError("no_info")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Role <span className="text-danger"> *</span>
                                </label>
                                <select
                                    name="role"
                                    value={this.state.role}
                                    onChange={this.handleChange}
                                    className={
                                        this.hasError("role")
                                            ? "form-control is-invalid select"
                                            : "form-control  select"
                                    }
                                >
                                    <option key="" value="">
                                        Please Select
                                    </option>
                                    {rolesUserInfo.map((role) => {
                                        return (
                                            <option
                                                key={role.key}
                                                value={role.value}
                                            >
                                                {role.value}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div
                                    className={
                                        this.hasError("role")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12"
                            style={{
                                paddingTop: "10px",
                                borderTop: "1px solid rgba(0,0,0,.2"
                            }}
                        >
                            <Button
                                style={{ float: "right", marginLeft: "10px" }}
                                variant="primary"
                                onClick={this.saveChanges}
                            >
                                Save Changes
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                variant="secondary"
                                onClick={this.props.handleClose}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
