import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { getUserDetails, isUserLoggedIn } from "./services/common/util";
import io from "socket.io-client";
import Swal from "sweetalert2";
import { colorValue, serviceConstants } from "./services/common/constants";
import { getSettings } from "./services/clinicPortalServices/settingService";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Slide
} from "@mui/material";
import { BLUELOT_ICON } from "./components/common/imgLogoes.jsx";
import ClinicPortalRouter from "./routers/ClinicPortalRouter.jsx";
import LIMSPortalRouter from "./routers/LIMSPortalRouter.jsx";
import CommonRouter from "./routers/CommonRouter.jsx";
import IdleTimer from "react-idle-timer";
import { logout } from "./services/clinicPortalServices/loginService";
import BlockUi from "@availity/block-ui";
import { FadeLoader } from "react-spinners";
import { loaderPositionStyle } from "./services/common/optionsData";
import { Connect } from "redux-zero/react";
import store from "./redux/store/index.js";
import actions from "./redux/actions/index.js";
import { bindActions } from "redux-zero/utils/";

const boundActions = bindActions(actions, store);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

let myTimeout = null;

class AppContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            isTriggered: false,
            showOverlay: false
        };
        this.idleTimer = null;
        this.handleOverlaySubmit = this.handleOverlaySubmit.bind(this);
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
    }

    handleOnAction = (event) => {
        clearInterval(myTimeout);
        this.idleTimer.reset();
    };

    handleOnIdle = (event) => {
        this.setState({ showOverlay: true });
        myTimeout = setTimeout(() => {
            logout();
        }, 1000 * 60 * 5);
    };

    handleOverlaySubmit = (event) => {
        this.setState({ showOverlay: false });
        this.idleTimer.reset();
    };

    handleClose = () => {
        window.localStorage.setItem("isNotificationEnabled", false);
        this.setState({ open: false });
    };

    handleAllow = () => {
        window.localStorage.setItem("isNotificationEnabled", true);
        this.setState({ open: false });
        window.Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                console.log("permission_granted");
            }
        });
    };

    triggerNotification = (objData) => {
        // windows notification
        window.Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
                new window.Notification(objData.title, {
                    body: objData.text,
                    icon: "https://benchlabit.com/public_files/blue-lot-icon.png"
                });
            }
        });
        // browser notification
        if (objData.target !== "superadmin" && objData.target !== "lab") {
            Swal.fire({
                customClass: {
                    container:
                        window.localStorage.getItem("appTheme") === "Dark" &&
                        /clinic|lims/.test(
                            window.location.pathname.split("/")[1]
                        ) &&
                        "dark-swal"
                },
                title: objData.title,
                text: objData.text,
                icon: "info",
                confirmButtonText: "Ok"
            });
        }
    };

    componentDidMount() {
        // enable windows notifications
        if (!window.localStorage.getItem("isNotificationEnabled")) {
            if (!("Notification" in window)) {
                console.log(
                    "This browser does not support desktop notifications"
                );
            } else if (window.Notification.permission !== "granted") {
                if (window.Notification.permission !== "denied") {
                    if (
                        !this.state.open &&
                        !this.state.isTriggered &&
                        !window.localStorage.getItem("isNotificationEnabled")
                    ) {
                        this.setState({ open: true, isTriggered: true });
                    }
                } else {
                    console.log("Browser notifications are denied");
                }
            }
        }

        // config socket
        const isLoggedIn = window.localStorage.getItem("USER_ID");
        let socketConfigOptions = {
            query: "userId=" + isLoggedIn,
            transports: ["websocket", "polling"]
        };
        let socketUrl = `${serviceConstants.API_HOST_NAME}`.replace(
            "http",
            "ws"
        );
        if (serviceConstants.API_HOST_NAME.includes("api")) {
            socketConfigOptions.path = "/api/socket.io";
            socketUrl = `${serviceConstants.HOST_NAME}`.replace("https", "wss");
        }
        socketConfigOptions.secure = true;
        let socket = io.connect(socketUrl, socketConfigOptions);
        window.socket = socket;
        socket.on("setting_maintenance_socket", (responseData) => {
            let objData = JSON.parse(responseData);
            let userName = "";
            if (getUserDetails()) {
                userName = JSON.parse(getUserDetails()).user_name;
            }
            if (
                (userName !== "creid" && userName !== "tai") ||
                objData.type !== "state"
            ) {
                if (objData.type === "state") {
                    window.location.href = objData.state ? "/comingSoon" : "/";
                } else {
                    // windows notification
                    if (!("Notification" in window)) {
                        alert(
                            "This browser does not support desktop notifications"
                        );
                    } else if (window.Notification.permission === "granted") {
                        this.triggerNotification(objData);
                    } else if (
                        window.Notification.permission !== "denied" &&
                        !window.localStorage.getItem("isNotificationEnabled")
                    ) {
                        window.Notification.requestPermission().then(function (
                            permission
                        ) {
                            if (permission === "granted") {
                                this.triggerNotification(objData);
                            }
                        });
                    }
                }
            }
        });
        socket.on("logout", (responseData) => {
            window.localStorage.clear();
            window.location.href = "/";
        });
    }

    render() {
        let userName = "";
        if (getUserDetails()) {
            const userDetails = JSON.parse(getUserDetails());
            userName = userDetails.user_name;
            if (
                store.getState().backgroundThemeColor !==
                userDetails.backgroundThemeColor
            ) {
                let color = "gray";
                if (userDetails.backgroundThemeColor) {
                    color = userDetails.backgroundThemeColor;
                }
                boundActions.setBackgroundThemeColor(color);
                document.getElementsByTagName("body")[0].className = color;
            }
        }
        if (this.props) {
            const pathName = this.props.location.pathname;
            const url = pathName.split("/")[1];
            if (userName !== "creid" && userName !== "tai") {
                getSettings().then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.state &&
                        url !== "comingSoon"
                    ) {
                        window.location.href = "/comingSoon";
                    }
                });
            }

            return (
                <Connect
                    mapToProps={({ blockUILoading, backgroundThemeColor }) => ({
                        blockUILoading,
                        backgroundThemeColor
                    })}
                >
                    {({ blockUILoading, backgroundThemeColor }) => {
                        return (
                            <div className={backgroundThemeColor}>
                                <Dialog
                                    open={this.state.open}
                                    TransitionComponent={Transition}
                                    sx={{ bottom: "unset" }}
                                    keepMounted
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogContent>
                                        <img
                                            src={BLUELOT_ICON}
                                            style={{
                                                width: "100px",
                                                float: "left",
                                                marginRight: "24px"
                                            }}
                                            alt="Mobile Logo"
                                        />
                                        <DialogContentText
                                            id="alert-dialog-slide-description"
                                            style={{ marginTop: "25px" }}
                                        >
                                            We would like to show you
                                            notifications for our latest news
                                            and updates
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions
                                        style={{ padding: "0 24px 24px 24px" }}
                                    >
                                        <Button
                                            onClick={this.handleClose}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={this.handleAllow}
                                            color="primary"
                                        >
                                            Allow
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <BlockUi
                                    tag="div"
                                    blocking={blockUILoading}
                                    loader={
                                        <FadeLoader
                                            height={12}
                                            width={5}
                                            radius={10}
                                            margin={2}
                                            css={loaderPositionStyle}
                                            color={
                                                colorValue[backgroundThemeColor]
                                            }
                                        />
                                    }
                                >
                                    <Router>
                                        <Switch>
                                            <Route
                                                path="/clinic/*"
                                                component={ClinicPortalRouter}
                                            />
                                            <Route
                                                path="/lims/*"
                                                component={LIMSPortalRouter}
                                            />
                                            <Route
                                                path="/"
                                                component={CommonRouter}
                                            />
                                        </Switch>
                                    </Router>
                                </BlockUi>
                                {isUserLoggedIn() && (
                                    <>
                                        <div
                                            id="myNav"
                                            className="idle-overlay"
                                            style={{
                                                width: this.state.showOverlay
                                                    ? "100%"
                                                    : "0%"
                                            }}
                                        >
                                            <div className="overlay-content">
                                                <p>Are You Still There ?</p>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={
                                                        this.handleOverlaySubmit
                                                    }
                                                >
                                                    Yes
                                                </button>
                                            </div>
                                        </div>

                                        <IdleTimer
                                            ref={(ref) => {
                                                this.idleTimer = ref;
                                            }}
                                            timeout={1000 * 60 * 300}
                                            onAction={this.handleOnAction}
                                            onIdle={this.handleOnIdle}
                                        />
                                    </>
                                )}
                            </div>
                        );
                    }}
                </Connect>
            );
        }
        return null;
    }
}

export default AppContainer;
