import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import TestDetails from "./testDetails";
import { deleteTest } from "../../../../services/clinicPortalServices/testService";
import { getUserDetails } from "../../../../services/common/util";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            testId: props.data._id,
            errors: []
        };

        this.userName = "";
        if (getUserDetails()) {
            this.userName = JSON.parse(getUserDetails()).user_name;
        }
    }

    handleShow = () => {
        this.setState({ show: true }, () => {
            let elements = document.getElementsByClassName(
                "removeTabIndexModal"
            );
            if (elements.length) {
                for (let i = 0; i < elements.length; i++) {
                    let dialog = elements[i];
                    dialog.parentNode.removeAttribute("tabindex");
                }
            }
        });
    };

    handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this test!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const info = {
                    _id: this.state.testId
                };
                deleteTest(info).then((response) => {
                    this.props.context.componentParent.loadGridData();
                });
            }
        });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    renderTooltipEdit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit Test
        </Tooltip>
    );

    renderTooltipDelete = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Delete Test
        </Tooltip>
    );

    render() {
        return (
            <div>
                <div>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 100 }}
                        overlay={this.renderTooltipEdit}
                    >
                        <button
                            onClick={this.handleShow}
                            className="edit-order-btn"
                        >
                            <i className="fas fa-pen"></i>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 100 }}
                        overlay={this.renderTooltipDelete}
                    >
                        <button
                            onClick={this.handleDelete}
                            className="edit-order-btn"
                        >
                            <i className="fas fa-trash"></i>
                        </button>
                    </OverlayTrigger>
                </div>
                <ModalStyled
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName="removeTabIndexModal"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Test Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TestDetails
                            testId={this.state.testId}
                            context={this.props.context}
                            handleClose={this.handleClose}
                        />
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}
