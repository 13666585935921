import React, { Component } from "react";
import {
    searchUserInfo,
    getUserInfoDataById
} from "../../../services/clinicPortalServices/userInfoService";
import { Popover, MenuItem } from "@mui/material";
import toastr from "toastr";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { getUserRole } from "../../../services/common/util";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

class UserInfoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            targetTemp: null,
            searchResults: [],
            showResultDetail: false,
            resultDetail: null
        };
    }

    handleChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        this.setState({ [key]: value });
    };

    handleSearch = (event) => {
        const searchFilters =
            getUserRole() &&
            (getUserRole().toLowerCase() === "facilityuser" ||
                getUserRole().toLowerCase() === "facilityadmin")
                ? { role: "Facility" }
                : { role: "BlueLot Admin" };
        const targetTemp = event.currentTarget;
        this.setState({ searchResults: [] });
        searchUserInfo(searchFilters).then((response) => {
            if (response.data?.length > 0) {
                this.setState({
                    searchResults: response.data,
                    anchorEl: targetTemp
                });
            } else {
                toastr.error("No data matched!");
            }
        });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleShowResultDetail = () => {
        this.setState({ showResultDetail: true });
    };

    handleCloseResultDetail = () => {
        this.setState({ showResultDetail: false });
    };

    handleSearchById = (_id) => {
        getUserInfoDataById(_id).then((response) => {
            if (response?.data) {
                this.setState({ resultDetail: response.data });
                this.handleShowResultDetail();
            }
        });
        this.handleClose();
    };

    renderTooltipSupport = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            User Info List
        </Tooltip>
    );

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;
        const { resultDetail } = this.state;

        return (
            <>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipSupport}
                >
                    <i
                        className="fa fa-info-circle fa-2x header-icon"
                        aria-hidden="true"
                        onClick={this.handleSearch}
                    ></i>
                </OverlayTrigger>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    style={{ height: "300px" }}
                >
                    {this.state.searchResults.length > 0 &&
                        this.state.searchResults.map((option) => (
                            <MenuItem
                                className="search-item-container"
                                key={option._id}
                                selected={option === "Pyxis"}
                                onClick={() =>
                                    this.handleSearchById(option._id)
                                }
                            >
                                {option.title}
                            </MenuItem>
                        ))}
                </Popover>
                <ModalStyled
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showResultDetail}
                    onHide={this.handleCloseResultDetail}
                    backdrop="static"
                >
                    {resultDetail && (
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>{resultDetail.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row form-row">
                                    {resultDetail.google_drive_link && (
                                            <div className="form-group col-12">
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        paddingBottom: "56.25%",
                                                        height: 0
                                                    }}
                                                >
                                                    <iframe
                                                        src={resultDetail.google_drive_link.replace(
                                                            "view",
                                                            "preview"
                                                        )}
                                                        width="640"
                                                        height="480"
                                                        allow="autoplay"
                                                        title="google drive user info iframe"
                                                        frameBorder="0"
                                                        webkitallowfullscreen="true"
                                                        mozallowfullscreen="true"
                                                        allowFullScreen={true}
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            height: "100%"
                                                        }}
                                                    ></iframe>
                                                </div>
                                            </div>
                                        )}
                                    {resultDetail.loom_embed_code && (
                                        <div className="form-group col-12">
                                            <div
                                                style={{
                                                    position: "relative",
                                                    paddingBottom: "56.25%",
                                                    height: 0
                                                }}
                                            >
                                                <iframe
                                                    src={
                                                        "https://www.loom.com/embed/" +
                                                        resultDetail.loom_embed_code
                                                    }
                                                    frameBorder="0"
                                                    webkitallowfullscreen="true"
                                                    mozallowfullscreen="true"
                                                    allowFullScreen={true}
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    title="loom video user Info iframe"
                                                ></iframe>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Modal.Body>
                        </>
                    )}
                </ModalStyled>
            </>
        );
    }
}

export default UserInfoList;
