import { apiInterfaceForJson } from "../common/apiManager";

export const fetchUserInfoes = () => {
    return apiInterfaceForJson("/userInfo/v1/", "GET");
};

export const searchUserInfo = (filterParams) => {
    return apiInterfaceForJson("/userInfo/v1/search", "POST", filterParams);
};


export const getUserInfoDataById = (videoId) => {
    return apiInterfaceForJson("/userInfo/v1/" + videoId, "GET");
};

export const createUserInfo = (videoDetails) => {
    return apiInterfaceForJson("/userInfo/v1/", "POST", videoDetails);
};

export const updateUserInfo = (videoDetails) => {
    return apiInterfaceForJson("/userInfo/v1/", "PUT", videoDetails);
};
