import React, { Component } from "react";
import {
    Modal,
    Button,
    Tooltip,
    OverlayTrigger,
    FormLabel
} from "react-bootstrap";
import {
    saveOrderEditData,
    getOrderDataById,
    orderEasyUpdate
} from "../../../../services/clinicPortalServices/orderEditService";
import { fetchPhysicians } from "../../../../services/clinicPortalServices/physicianServices";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import {
    createRequisitionPDF,
    revertCancelRequisition
} from "../../../../services/clinicPortalServices/requisitionService";
import moment from "moment";
import { getUserDetails } from "../../../../services/common/util";
// import VipButton from "./vipButton";
import {
    FormControl,
    Checkbox,
    Select,
    InputLabel,
    MenuItem
} from "@mui/material";
import Swal from "sweetalert2";
import toastr from "toastr";
import OrderViewPopup from "../../orders/clinicOrderGrid/orderViewPopup";
import UpgradePanelPopup from "./upgradePanelPopup";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showRevertCancelPopup: false,
            orderId: props.data._id,
            gender: "U",
            dob: "",
            mrn: "",
            provider: "",
            facilitySource: "",
            facilityDetails: {},
            receivedDate: "",
            description: "",
            specimenType: "",
            sampleType: "",
            diagnosisCode: [],
            sample: "",
            result: "",
            collectedDate: "",
            collectorName: "",
            requisition: "",
            code: "",
            codeType: "",
            symptoms: [],
            patientDetails: null,
            patientName: "",
            email: "",
            mobile: "",
            providerDetails: null,
            providerFirstName: "",
            providerLastName: "",
            providerNPI: "",
            facilityId: "",
            insuranceDetails: null,
            errors: [],
            saving: false,
            showOrderViewPopup: false,
            testDetails: {}
        };

        this.userName = "";
        if (getUserDetails()) {
            this.userName = JSON.parse(getUserDetails()).user_name;
        }

        this.loadOrderDetails();
    }

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    loadOrderDetails = () => {
        getOrderDataById(this.state.orderId).then((response) => {
            let orderDetails = response.data;
            this.setState({
                patientName:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id.first_name +
                          " " +
                          orderDetails.patient_id.last_name
                        : "",
                patientDetails:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id
                        : {},
                mrn:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id.mrn
                        : "",
                dob:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id.date_of_birth
                        : "",
                gender:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id.gender
                        : "U",
                email:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id.email
                        : "",
                mobile:
                    orderDetails && orderDetails.patient_id
                        ? orderDetails.patient_id.mobile
                        : "",
                provider:
                    orderDetails && orderDetails.provider
                        ? orderDetails.provider.first_name +
                          " " +
                          orderDetails.provider.last_name
                        : "",
                providerNPI:
                    orderDetails && orderDetails.provider
                        ? orderDetails.provider.npi
                        : "",
                providerFirstName:
                    orderDetails && orderDetails.provider
                        ? orderDetails.provider.first_name
                        : "",
                providerLastName:
                    orderDetails && orderDetails.provider
                        ? orderDetails.provider.last_name
                        : "",
                facilitySource: orderDetails
                    ? orderDetails.facility_source
                    : "",
                facilityDetails:
                    orderDetails &&
                    orderDetails.facility_id &&
                    orderDetails.facility_id._id
                        ? orderDetails.facility_id
                        : {},
                description:
                    orderDetails && orderDetails.test_info
                        ? orderDetails.test_info.description
                        : "",
                specimenType:
                    orderDetails && orderDetails.test_info
                        ? orderDetails.test_info.specimenType
                        : "",
                sampleType:
                    orderDetails && orderDetails.test_info
                        ? orderDetails.test_info.sampleType
                        : "",
                diagnosisCode:
                    orderDetails &&
                    orderDetails.diagnosis_code &&
                    orderDetails.diagnosis_code.length
                        ? orderDetails.diagnosis_code
                        : [],
                requisition:
                    orderDetails && orderDetails.lab_order_id
                        ? orderDetails.lab_order_id
                        : "",
                sample:
                    orderDetails && orderDetails.test_info
                        ? orderDetails.test_info.sample
                        : "",
                collectedDate:
                    orderDetails &&
                    orderDetails.test_info &&
                    orderDetails.test_info.collected
                        ? moment(
                              orderDetails.test_info.collected,
                              "YYYYMMDDHHmmss"
                          ).format("MM/DD/YYYY hh:mm:ss A")
                        : "",
                collectorName:
                    orderDetails &&
                    orderDetails.test_info &&
                    orderDetails.test_info.collector_name
                        ? orderDetails.test_info.collector_name
                        : "",
                receivedDate:
                    orderDetails &&
                    orderDetails.test_info &&
                    orderDetails.test_info.received
                        ? moment(
                              orderDetails.test_info.received,
                              "YYYYMMDDHHmmss"
                          ).format("MM/DD/YYYY hh:mm:ss A")
                        : "",
                result:
                    orderDetails && orderDetails.results
                        ? orderDetails.results.value
                        : "",
                facilityId: orderDetails.facility_id
                    ? orderDetails.facility_id._id
                    : "",
                insuranceDetails: orderDetails.patient_insurance_id
                    ? orderDetails.patient_insurance_id
                    : null,
                symptoms:
                    orderDetails.symptoms && orderDetails.symptoms.length
                        ? orderDetails.symptoms
                        : [],
                testDetails: orderDetails.test_id
            });
            if (orderDetails.facility_id && orderDetails.facility_id._id) {
                fetchPhysicians(orderDetails.facility_id._id).then(
                    (response) => {
                        this.setState({
                            providers: response.data
                        });
                        if (response.data.length) {
                            const provider = response.data.filter(
                                (provider) =>
                                    Number(provider.npi) ===
                                    Number(this.state.providerNPI)
                            )[0];
                            if (provider) {
                                this.setState({
                                    selectedProviderId: provider._id,
                                    providerDetails: provider
                                });
                            }
                        } else {
                            this.setState({
                                selectedProviderId: "",
                                providerFirstName: "",
                                providerLastName: "",
                                providerNPI: "",
                                providerDetails: null
                            });
                        }
                    }
                );
            }
        });
    };

    handleShow = () => {
        if (this.state.orderId !== "") {
            this.loadOrderDetails();
            this.setState({ errors: [], saving: false });
        }
        this.setState({ show: true });
    };

    handleClose = () => {
        this.setState({ show: false, showRevertCancelPopup: false });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "facilityId") {
            const facility = this.state.facilities.filter(
                (fac) => fac._id === e.target.value
            )[0];
            this.setState({
                facilitySource: facility.name,
                facilityDetails: facility
            });
            this.getPhysicians(e.target.value);
        }
        if (e.target.name === "selectedProviderId") {
            let index = this.state.providers.findIndex(
                (i) => i._id === e.target.value
            );
            if (index > -1) {
                this.setState({
                    providerFirstName: this.state.providers[index].first_name,
                    providerLastName: this.state.providers[index].last_name,
                    providerNPI: this.state.providers[index].npi,
                    providerDetails: this.state.providers[index]
                });
            }
        }
        if (e.target.name === "symptoms") {
            const value = e.target.value;
            let symptoms = this.state.symptoms;
            if (value === "None") {
                symptoms = ["None"];
            } else {
                if (
                    symptoms.findIndex((element) => element === value) === -1 &&
                    e.target.checked
                ) {
                    symptoms.push(value);
                } else if (
                    symptoms.findIndex((element) => element === value) !== -1 &&
                    !e.target.checked
                ) {
                    symptoms.splice(
                        symptoms.findIndex((element) => element === value),
                        1
                    );
                }
                symptoms = symptoms.filter((symptom) => symptom !== "None");
            }
            this.setState({ symptoms: symptoms });
        }
    };

    loadDataForModal = () => {
        fetchFacilitiesForOrders().then((res) => {
            let filteredFacilities = [];
            res.data.map((facility) => {
                if (facility.testIds && facility.testIds.length) {
                    facility.testIds.map((test) => {
                        if (test._id === this.state.testDetails._id) {
                            filteredFacilities.push(facility);
                        }
                        return null;
                    });
                }
                return null;
            });
            this.setState({ facilities: filteredFacilities });
            const facility = filteredFacilities.filter(
                (fac) => fac._id === this.props.data.facility_id._id
            )[0];
            this.setState({ facilityDetails: facility });
        });
    };

    getPhysicians = (facilityId) => {
        fetchPhysicians(facilityId).then((response) => {
            this.setState({
                providers: response.data
            });
            if (response.data.length) {
                this.setState({
                    selectedProviderId: response.data[0]._id,
                    providerFirstName: response.data[0].first_name,
                    providerLastName: response.data[0].last_name,
                    providerNPI: response.data[0].npi,
                    providerDetails: response.data[0]
                });
            } else {
                this.setState({
                    selectedProviderId: "",
                    providerFirstName: "",
                    providerLastName: "",
                    providerNPI: "",
                    providerDetails: null
                });
            }
        });
    };

    handleOrderEditChanges = () => {
        let errors = [];
        if (!this.state.sampleType) {
            errors.push("sampleType");
        }
        if (!this.state.collectedDate) {
            errors.push("collectedDate");
        }
        if (
            this.state.collectedDate &&
            moment(this.state.collectedDate, "MM/DD/YYYY hh:mm:ss A").format(
                "YYYYMMDDHHmmss"
            ) === "Invalid date"
        ) {
            errors.push("wrongCollectedDate");
        }
        if (!this.state.diagnosisCode.length) {
            errors.push("diagnosisCode");
        }
        if (!this.state.sample) {
            errors.push("sample");
        }
        if (errors.length) {
            this.setState({ errors: errors });
        } else {
            this.setState({ saving: true });
            const editParams = {
                orderId: this.state.orderId,
                description: this.state.description,
                specimenType: this.state.specimenType,
                sampleType: this.state.sampleType,
                collectorName: this.state.collectorName,
                diagnosis_code: this.state.diagnosisCode,
                sample: this.state.sample,
                collectedDate: this.state.collectedDate
                    ? moment(
                          this.state.collectedDate,
                          "MM/DD/YYYY hh:mm:ss A"
                      ).format("YYYYMMDDHHmmss")
                    : "",
                provider: this.state.provider,
                receivedDate: this.state.receivedDate
                    ? moment(
                          this.state.receivedDate,
                          "MM/DD/YYYY hh:mm:ss A"
                      ).format("YYYYMMDDHHmmss")
                    : "",
                requisition: this.state.requisition,
                patientName: this.state.patientName,
                email: this.state.email,
                mobile: this.state.mobile,
                mrn: this.state.mrn,
                dob: this.state.dob,
                updateRequisition: true,
                facility_id: this.state.facilityId,
                facility_source: this.state.facilitySource,
                providerFirstName: this.state.providerFirstName,
                providerLastName: this.state.providerLastName,
                providerNPI: this.state.providerNPI,
                requisition_pdf_path:
                    this.state.sample.toString() +
                    "-" +
                    moment(
                        this.state.collectedDate,
                        "MM/DD/YYYY hh:mm:ss A"
                    ).format("YYYYMMDDHHmmss") +
                    ".pdf",
                symptoms:
                    this.state.symptoms && this.state.symptoms.length
                        ? this.state.symptoms
                        : []
            };
            saveOrderEditData(editParams).then((res) => {
                let pdfParams = {
                    patientName: this.state.patientName,
                    address1:
                        this.state.patientDetails.address &&
                        this.state.patientDetails.address.address1
                            ? this.state.patientDetails.address.address1
                            : "",
                    address2:
                        this.state.patientDetails.address &&
                        this.state.patientDetails.address.address2
                            ? this.state.patientDetails.address.address2
                            : "",
                    city:
                        this.state.patientDetails.address &&
                        this.state.patientDetails.address.city
                            ? this.state.patientDetails.address.city
                            : "",
                    state:
                        this.state.patientDetails.address &&
                        this.state.patientDetails.address.state
                            ? this.state.patientDetails.address.state
                            : "",
                    zip:
                        this.state.patientDetails.address &&
                        this.state.patientDetails.address.zip
                            ? this.state.patientDetails.address.zip
                            : "",
                    phoneNumber: this.state.patientDetails.mobile
                        ? this.state.patientDetails.mobile
                        : "",
                    email: this.state.patientDetails.email
                        ? this.state.patientDetails.email
                        : "",
                    dateOfBirth: this.state.patientDetails.date_of_birth
                        ? moment(
                              this.state.patientDetails.date_of_birth,
                              "YYYY-MM-DD"
                          ).format("MM/DD/YYYY")
                        : "",
                    gender: this.state.patientDetails.gender
                        ? this.state.patientDetails.gender
                        : "U",
                    sample: this.state.sample
                        ? this.state.sample.toString()
                        : "",
                    physicianName:
                        this.state.providerFirstName +
                        " " +
                        this.state.providerLastName,
                    facilityName: this.state.facilitySource,
                    providerNPI: this.state.providerNPI,
                    facilityAddress1: this.state.facilityDetails.address
                        .address1
                        ? this.state.facilityDetails.address.address1
                        : "",
                    facilityAddress2: this.state.facilityDetails.address
                        .address2
                        ? this.state.facilityDetails.address.address2
                        : "",
                    facilityCity: this.state.facilityDetails.address.city
                        ? this.state.facilityDetails.address.city
                        : "",
                    facilityState: this.state.facilityDetails.address.state
                        ? this.state.facilityDetails.address.state
                        : "",
                    facilityZip: this.state.facilityDetails.address.zip
                        ? this.state.facilityDetails.address.zip
                        : "",
                    facilityPhone: this.state.facilityDetails.phone_no,
                    specimenType: this.state.specimenType,
                    sampleType: this.state.sampleType,
                    collectedDate: moment(
                        editParams.collectedDate,
                        "YYYYMMDDHHmmss"
                    ).format("MM/DD/YYYY"),
                    collectedTime: moment(
                        editParams.collectedDate,
                        "YYYYMMDDHHmmss"
                    ).format("hh:mm A"),
                    signature_image:
                        this.state.providerDetails &&
                        this.state.providerDetails.signature_image
                            ? this.state.providerDetails.signature_image
                            : "",
                    requisition_pdf_path:
                        this.state.sample.toString() +
                        "-" +
                        editParams.collectedDate +
                        ".pdf",
                    diagnosisCode: this.state.diagnosisCode,
                    symptoms:
                        this.state.symptoms && this.state.symptoms.length
                            ? this.state.symptoms
                            : [],
                    insuranceProvider:
                        this.state.insuranceDetails &&
                        this.state.insuranceDetails.insurance_provider
                            ? this.state.insuranceDetails.insurance_provider
                            : "",
                    insuranceMemberId:
                        this.state.insuranceDetails &&
                        this.state.insuranceDetails.insured_member_id
                            ? this.state.insuranceDetails.insured_member_id
                            : "",
                    insuranceGroupNumber:
                        this.state.insuranceDetails &&
                        this.state.insuranceDetails.insured_group_number
                            ? this.state.insuranceDetails.insured_group_number
                            : "",
                    insuranceRelationToInsured:
                        this.state.insuranceDetails &&
                        this.state.insuranceDetails.relation_to_insured
                            ? this.state.insuranceDetails.relation_to_insured
                            : "",
                    insuranceHolderName:
                        this.state.insuranceDetails &&
                        this.state.insuranceDetails.insured_first_name
                            ? this.state.insuranceDetails.insured_first_name +
                              " " +
                              this.state.insuranceDetails.insured_last_name
                            : "",
                    insuranceDriverLicense: this.state.patientDetails
                        .driver_license
                        ? this.state.patientDetails.driver_license
                        : "",
                    insuranceDriverLicenseState: this.state.patientDetails
                        .driver_license_state
                        ? this.state.patientDetails.driver_license_state
                        : "",
                    lab_order_id: this.state.requisition,
                    testId: this.state.testDetails._id,
                    mrn: this.state.patientDetails.mrn
                };
                createRequisitionPDF(pdfParams).then((res) => {
                    this.handleClose();
                    if (
                        this.props.context &&
                        this.props.context.componentParent &&
                        !(
                            this.props.type &&
                            this.props.type === "patientViewPopup"
                        )
                    ) {
                        this.props.context.componentParent.loadGridData(
                            this.props.context.componentParent.state.isCancelled
                        );
                    }
                });
            });
        }
    };

    handleRevert = () => {
        const revertCancelData = {
            _id: this.props.data._id
        };
        revertCancelRequisition(revertCancelData).then((respond) => {
            this.handleClose();
            if (
                this.props.context &&
                this.props.context.componentParent &&
                this.props.context.componentParent.loadGridData
            ) {
                this.props.context.componentParent.loadGridData(
                    this.props.context.componentParent.state.isCancelled
                );
            } else {
                this.props.context.componentParent.props.handleClose();
            }
        });
    };

    renderTooltipEdit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit Requisition
        </Tooltip>
    );

    renderTooltipRevertCancel = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Revert Cancellation
        </Tooltip>
    );

    renderTooltipResetAccessioning = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Reset sample for accessioning
        </Tooltip>
    );

    renderTooltipView = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            View
        </Tooltip>
    );

    renderTooltipUpgrade = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Upgrade to Panel
        </Tooltip>
    );

    handleShowRevertCancelPopup = () => {
        this.setState({ showRevertCancelPopup: true });
    };

    handleUpgradeBundle = () => {
        this.setState({ showUpgradePanelPopup: true });
    };

    handleResetAccessioning = () => {
        Swal.fire({
            title: "Revert Accession Status",
            text:
                "This will make accessioned status to false so sample " +
                this.props.data.test_info.sample.toString() +
                " can be plated again.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container:
                    window.localStorage.getItem("appTheme") === "Dark" &&
                    /clinic|lims/.test(
                        window.location.pathname.split("/")[1]
                    ) &&
                    "dark-swal",
                cancelButton: "order-1",
                confirmButton: "order-2"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    _id: this.state.orderId,
                    is_assigned_to_plate: false
                };
                orderEasyUpdate(data).then((res) => {
                    if (
                        this.props.context &&
                        this.props.context.componentParent &&
                        this.props.context.componentParent.loadGridData
                    ) {
                        this.props.context.componentParent.loadGridData(
                            this.props.context.componentParent.state.isCancelled
                        );
                    } else {
                        this.props.context.componentParent.props.handleClose();
                    }
                    toastr.success("Successfully reset");
                });
            }
        });
    };

    render() {
        return (
            <div className="d-flex align-items-center">
                {/* <VipButton {...this.props} /> */}
                {!(
                    this.props.data.order_type &&
                    this.props.data.order_type === "sub_bundle"
                ) && (
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 100 }}
                        overlay={this.renderTooltipEdit}
                    >
                        <button
                            onClick={this.handleShow}
                            className="edit-order-btn"
                        >
                            <i className="fas fa-pen"></i>
                        </button>
                    </OverlayTrigger>
                )}
                {this.props.data.is_cancel &&
                    !(
                        this.props.data.bundle_id &&
                        this.props.data.bundle_id.results &&
                        this.props.data.bundle_id.results.value &&
                        this.props.data.bundle_id.results.value.includes(
                            " - Full Results"
                        )
                    ) && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 100 }}
                            overlay={this.renderTooltipRevertCancel}
                        >
                            <button
                                onClick={this.handleShowRevertCancelPopup}
                                className="edit-order-btn"
                            >
                                <i className="fas fa-envelope"></i>
                            </button>
                        </OverlayTrigger>
                    )}

                {this.props.data.order_type !== "sub_bundle" &&
                    [
                        "6333a8a8e136fdfe8a0c36c9",
                        "63745e2c86c4ce6c4ca996da"
                    ].includes(this.props.data.test_id._id) &&
                    !this.props.data.is_assigned_to_plate &&
                    this.props.data.facility_id.testIds &&
                    this.props.data.facility_id.testIds.length &&
                    (this.props.data.facility_id.testIds.find((item) =>
                        item.name.includes("Comprehensive")
                    ) ||
                        (this.props.data.facility_id.testIds.find((item) =>
                            item.name.includes("Standard")
                        ) &&
                            this.props.data.test_id._id ===
                                "6333a8a8e136fdfe8a0c36c9")) && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 100 }}
                            overlay={this.renderTooltipUpgrade}
                        >
                            <button
                                onClick={this.handleUpgradeBundle}
                                className="edit-order-btn"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    width="16px"
                                    style={{ marginTop: "-3px" }}
                                >
                                    <path
                                        fill="currentColor"
                                        d="M416 320h-96c-17.6 0-32 14.4-32 32v96c0 17.6 14.4 32 32 32h96c17.6 0 32-14.4 32-32v-96C448 334.4 433.6 320 416 320zM480 32h-160c-17.67 0-32 14.33-32 32v160c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32V64C512 46.33 497.7 32 480 32zM151.6 41.95c-12.12-13.26-35.06-13.26-47.19 0l-87.1 96.09C4.473 151.1 5.348 171.4 18.38 183.3c13.02 11.95 33.27 11.04 45.22-1.973L96 145.9v302C96 465.7 110.3 480 128 480s32-14.33 32-32.03V145.9L192.4 181.3c6.312 6.883 14.94 10.39 23.61 10.39c7.719 0 15.47-2.785 21.61-8.414c13.03-11.95 13.9-32.22 1.969-45.27L151.6 41.95z"
                                    />
                                </svg>
                            </button>
                        </OverlayTrigger>
                    )}
                {this.props.data.sample_tracking.filter(
                    (item) => item.eventName === "ACCESSIONED"
                ).length > 0 &&
                    this.props.data.is_assigned_to_plate &&
                    !(
                        this.props.data.results && this.props.data.results.value
                    ) && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 50, hide: 100 }}
                            overlay={this.renderTooltipResetAccessioning}
                        >
                            <button
                                onClick={this.handleResetAccessioning}
                                className="edit-order-btn"
                            >
                                <i className="fas fa-rotate-left"></i>
                            </button>
                        </OverlayTrigger>
                    )}
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipView}
                >
                    <button
                        onClick={() =>
                            this.setState({ showOrderViewPopup: true })
                        }
                        className="edit-order-btn"
                    >
                        <i className="fas fa-eye"></i>
                    </button>
                </OverlayTrigger>

                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    onEnter={this.loadDataForModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Requisition Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Patient Name</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            name="patientName"
                                            value={this.state.patientName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>MRN</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            name="mrn"
                                            value={this.state.mrn}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Date Of Birth</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            name="dob"
                                            value={this.state.dob}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="form-control"
                                            name="gender"
                                            value={this.state.gender}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Facility</label>
                                        <select
                                            required
                                            type="text"
                                            name="facilityId"
                                            value={this.state.facilityId}
                                            onChange={this.handleChange}
                                            className="form-control"
                                        >
                                            {this.state.facilities &&
                                                this.state.facilities.map(
                                                    (fac) => {
                                                        return (
                                                            <option
                                                                value={fac._id}
                                                                key={fac._id}
                                                            >
                                                                {fac.name}{" "}
                                                                {fac.archived ===
                                                                "archived"
                                                                    ? " ( Archived ) "
                                                                    : ""}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Physician</label>
                                        <select
                                            required
                                            type="text"
                                            name="selectedProviderId"
                                            value={
                                                this.state.selectedProviderId
                                            }
                                            onChange={this.handleChange}
                                            className="form-control"
                                        >
                                            {this.state.providers &&
                                                this.state.providers.map(
                                                    (prov) => {
                                                        return (
                                                            <option
                                                                value={prov._id}
                                                                key={prov._id}
                                                            >
                                                                {prov.first_name +
                                                                    " " +
                                                                    prov.last_name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Test Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    disabled
                                                    value={
                                                        this.state.testDetails
                                                            .name
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Test Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    disabled
                                                    value={
                                                        this.state.description
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Specimen Type</label>
                                                <input
                                                    className="form-control"
                                                    name="specimenType"
                                                    value={
                                                        this.state.specimenType
                                                    }
                                                    disabled
                                                />
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "specimenType"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                    style={{
                                                        width: "auto",
                                                        fontSize: "15px"
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;This field is
                                                        required
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Sample Type</label>
                                                <input
                                                    className="form-control"
                                                    name="sampleType"
                                                    value={
                                                        this.state.sampleType
                                                    }
                                                    disabled
                                                />
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "sampleType"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                    style={{
                                                        width: "auto",
                                                        fontSize: "15px"
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;This field is
                                                        required
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Collected Date</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="collectedDate"
                                                    value={
                                                        this.state.collectedDate
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <label
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    Date format - MM/DD/YYYY
                                                    hh:mi:ss AM/PM
                                                </label>
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "collectedDate"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                    style={{
                                                        width: "auto",
                                                        fontSize: "15px"
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;This field is
                                                        required
                                                    </i>
                                                </div>
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "wrongCollectedDate"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                    style={{
                                                        width: "auto",
                                                        fontSize: "15px"
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;Please provide
                                                        correctly
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <FormControl
                                                    variant="outlined"
                                                    style={{
                                                        width: "100%",
                                                        marginTop: "5px"
                                                    }}
                                                >
                                                    <InputLabel id="diagnosisCode-label">
                                                        Diagnosis Code For Visit
                                                    </InputLabel>
                                                    <Select
                                                        labelId="diagnosisCode-label"
                                                        id="diagnosisCode-select"
                                                        value={
                                                            this.state
                                                                .diagnosisCode
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        label="Diagnosis Code For Visit"
                                                        name="diagnosisCode"
                                                        multiple
                                                        renderValue={(
                                                            selected
                                                        ) => {
                                                            return selected.join(
                                                                ", "
                                                            );
                                                        }}
                                                    >
                                                        {this.state
                                                            .testDetails &&
                                                            this.state
                                                                .testDetails
                                                                ._id &&
                                                            this.state.testDetails.diagnosis_codes.map(
                                                                (code) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                code
                                                                            }
                                                                            value={
                                                                                code.code +
                                                                                " " +
                                                                                code.text
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                checked={
                                                                                    this.state.diagnosisCode.includes(
                                                                                        code.code +
                                                                                            " " +
                                                                                            code.text
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                            />
                                                                            {code.code +
                                                                                " " +
                                                                                code.text}
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                                <div
                                                    className={
                                                        this.hasError(
                                                            "diagnosisCode"
                                                        )
                                                            ? "inline-errormsg"
                                                            : "hidden"
                                                    }
                                                    style={{
                                                        width: "auto",
                                                        fontSize: "15px"
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        aria-hidden="true"
                                                    >
                                                        &nbsp;This field is
                                                        required
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Sample</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sample"
                                            value={this.state.sample}
                                            onChange={this.handleChange}
                                        />
                                        <div
                                            className={
                                                this.hasError("sample")
                                                    ? "inline-errormsg"
                                                    : "hidden"
                                            }
                                            style={{
                                                width: "auto",
                                                fontSize: "15px"
                                            }}
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                aria-hidden="true"
                                            >
                                                &nbsp;This field is required
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <FormControl>
                                            <FormLabel>
                                                Symptoms{" "}
                                                <abbr className="text-danger">
                                                    *
                                                </abbr>
                                            </FormLabel>
                                            {this.state.testDetails &&
                                                this.state.testDetails
                                                    .symptoms &&
                                                this.state.testDetails.symptoms.map(
                                                    (item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Checkbox
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .symptoms &&
                                                                        this.state.symptoms.findIndex(
                                                                            (
                                                                                elem
                                                                            ) =>
                                                                                elem ===
                                                                                item
                                                                        ) !== -1
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    value={item}
                                                                    name="symptoms"
                                                                />
                                                                <span>
                                                                    {item}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleOrderEditChanges}
                        >
                            {this.state.saving && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                ></i>
                            )}
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </ModalStyled>

                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showRevertCancelPopup}
                    onHide={this.handleClose}
                    onEnter={this.loadDataForModal}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Revert Cancellation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Facility</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.facilitySource}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            The cancellation of the requisition
                                            is reverted and the email is sent to
                                            the facility.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleRevert}>
                            Revert & Email Facillity
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
                <OrderViewPopup
                    show={this.state.showOrderViewPopup}
                    handleClose={() =>
                        this.setState({ showOrderViewPopup: false })
                    }
                    data={this.props.data}
                    context={this.props.context}
                    type="requisition"
                />
                <UpgradePanelPopup
                    show={this.state.showUpgradePanelPopup}
                    handleClose={() =>
                        this.setState({ showUpgradePanelPopup: false })
                    }
                    data={this.props.data}
                    context={this.props.context}
                />
            </div>
        );
    }
}
