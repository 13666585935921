export const serviceConstants = {
    HOST_NAME: process.env.REACT_APP_HOST_NAME,
    API_HOST_NAME: process.env.REACT_APP_API_HOST_NAME,
    SOCKET_HOST_NAME: process.env.REACT_APP_SOCKET_HOST_NAME,
    STRIPE_API_PUBLIC_KEY: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY,
    AG_GRID_LICENSE_KEY: process.env.REACT_APP_AG_GRID_LICENSE_KEY,
    VERSION: "3.82"
};

export const templateIds = {
    htmlEmailWithLogo: "d-37463a895a614d65a0f425fa31c1e79c"
};

export const auditEnums = {
    IDENTIFIERS: {
        PatientRecord: "Patient_Record",
        OrderRecord: "Order_Record",
        ExportRecord: "Export_Record",
        ImportRecord: "Import_Record"
    },
    EVENTTYPES: {
        PatientLogIn: "Patient_LoggedIn",
        PatientLogOut: "Patient_LogOut",
        PatientVerificationPass: "Patient_Verification_Passed",
        PatientSetUsernamePassword: "Patient_Set_Username_Password",
        PatientUpdatePassword: "Patient_Update_Password",
        OrderResultViewed: "Order_Result_Viewed",
        OrderResultDownloaded: "Order_Result_Downloaded",
        OrderGridExported: "Order_Grid_Exported",
        RequisitionGridExported: "Requisition_Grid_Exported",
        PatientGridExported: "Patient_Grid_Exported",
        PatientInsuranceGridExported: "Patient_Insurance_Grid_Exported",
        PatientDuplicateGridExported: "Patient_Duplicate_Grid_Exported",
        InvoiceGridExported: "Invoice_Grid_Exported",
        SelfPaymentGridExported: "Self_Payment_Grid_Exported",
        AuditGridExported: "Audit_Grid_Exported",
        BrooksLIMSRecordGridExported: "Brooks_LIMS_Record_Grid_Exported",
        StateReportGridExported: "State_Report_Grid_Exported",
        EndOfDayReportGridExported: "EndOfDay_Report_Grid_Exported",
        FailedSMSEmailReportGridExported: "FailedSMSEmail_Report_Grid_Exported",
        OrderTotalGridExported: "Order_Total_Grid_Exported",
        CompanyGridExported: "Company_Grid_Exported",
        FacilityGridExported: "Facility_Grid_Exported",
        LocationGridExported: "Location_Grid_Exported",
        PhysicianGridExported: "Physician_Grid_Exported",
        UserGridExported: "User_Grid_Exported",
        FacilityPatientTestResultManagementGridExported:
            "Facility_Patient_Test_Result_Management_Grid_Exported",
        FacilityInventoryGridExported: "FacilityInventory_Grid_Exported",
        AnalyteGridExported: "Analyte_Grid_Exported",
        LabGridExported: "Lab_Grid_Exported",
        LabInventoryGridExported: "LabInventory_Grid_Exported",
        LabInventoryTrackingHistoryGridExported:
            "LabInventory_Tracking_History_Grid_Exported",
        DeliveryPickupOrderGridExported: "DeliveryPickupOrder_Grid_Exported",
        DriverShiftInformationGridExported:
            "DriverShift_Information_Grid_Exported",
        DriverGridExported: "Driver_Grid_Exported",
        NotificationTypeGridExported: "NotificationType_Grid_Exported",
        FacilityNotificationManagementGridExported:
            "FacilityNotification_Management_Grid_Exported",
        UserInfoManagementGridExported: "UserInfo_Management_Grid_Exported",
        LIMSResultImportGridExported: "LIMS_ResultImport_Grid_Exported",
        InsuranceProviderGridExported: "Insurance_Provider_Grid_Exported",
        MaintenanceGridExported: "Maintenance_Grid_Exported",
        SelfPaymentGridDocumentImported: "Self_Payment_Grid_Document_Imported"
    }
};

export const patientImagePath = "/files/patient/images/";

export const CropImgMaxWidth = 600;

export const colorValue = {
    slate: "#64748b",
    gray: "#6b7280",
    zinc: "#71717a",
    neutral: "#737373",
    stone: "#78716c",
    red: "#ef4444",
    orange: "#f97316",
    amber: "#f59e0b",
    yellow: "#eab308",
    lime: "#84cc16",
    green: "#22c55e",
    emerald: "#10b981",
    teal: "#14b8a6",
    cyan: "#06b6d4",
    sky: "#0ea5e9",
    blue: "#3b82f6",
    indigo: "#6366f1",
    violet: "#8b5cf6",
    purple: "#a855f7",
    fuchsia: "#d946ef",
    pink: "#ec4899",
    rose: "#f43f5e"
};

export const notificationTemplateValues = [
    {
        name: "patient_first_name",
        label: "Patient First Name"
    },
    {
        name: "patient_last_name",
        label: "Patient Last Name"
    },
    {
        name: "patient_full_name",
        label: "Patient Full Name"
    },
    {
        name: "test_name",
        label: "Test Name"
    },
    {
        name: "facility_name",
        label: "Facility Name"
    },
    {
        name: "sample_id",
        label: "Sample ID"
    },
    {
        name: "requisition_number",
        label: "Requisition Number"
    },
    {
        name: "collected_time",
        label: "Collected Time"
    },
    {
        name: "lims_url",
        label: "LIMS URL"
    },
    {
        name: "patient_portal_url",
        label: "Patient Portal URL"
    }
];
