import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ClinicPortalLoginContainer from "../components/clinicPortal/login";
import ClinicDashboard from "../components/clinicPortal/dashboard/clinicDashboard";
import ClinicPortalLayout from "../layouts/ClinicPortalLayout";
import ClinicOrderGrid from "../components/clinicPortal/orders/clinicOrderGrid";
import ClinicRequisitionGrid from "../components/clinicPortal/requisitions/clinicRequisitionGrid";
import ClinicRequisitionNewGrid from "../components/clinicPortal/requisitionsNew/clinicRequisitionNewGrid";
import ClinicResultLinksGrid from "../components/clinicPortal/resultLinks/clinicResultLinksGrid";
import ClinicHl7MessageGrid from "../components/clinicPortal/hl7MessageGrid";
import ClinicFacilityCommunications from "../components/clinicPortal/communication/clinicFacilityCommunications";
import ClinicCommunicationDetail from "../components/clinicPortal/communication/clinicCommunicationDetail";
import ClinicPatientGrid from "../components/clinicPortal/patients/clinicPatientGrid";
import ClinicPatientDuplicatedGrid from "../components/clinicPortal/patientsDuplicated/ClinicPatientDuplicatedGrid";
import ClinicInsuranceGrid from "../components/clinicPortal/insurance/clinicInsuranceGrid";
import ClinicInvoiceGrid from "../components/clinicPortal/invoices/ClinicInvoiceGrid";
import ClinicSelfPaymentGrid from "../components/clinicPortal/patientPayment/clinicSelfPaymentGrid";
import ClinicInsuranceProviderGrid from "../components/clinicPortal/insurance/clinicInsuranceProviderGrid";
import ClinicAuditGrid from "../components/clinicPortal/audit/clinicAuditGrid";
import ClinicStateReportsGrid from "../components/clinicPortal/reports/ClinicStateReportsGrid";
import ClinicDailyReportsGrid from "../components/clinicPortal/reports/ClinicDailyReportsGrid";
import ClinicFailedNotificationReportsGrid from "../components/clinicPortal/reports/ClinicFailedNotificationReportsGrid";
import ClinicOrderReportsGrid from "../components/clinicPortal/reports/ClinicOrderTotalReportsGrid";
import ClinicVipPaymentGrid from "../components/clinicPortal/vipPayment/clinicVipPaymentGrid";
import ClinicCompanyGrid from "../components/clinicPortal/company/clinicCompanyGrid";
import ClinicFacilityGrid from "../components/clinicPortal/facility/clinicFacilityGrid";
import ClinicFacilityTestManagementGrid from "../components/clinicPortal/facility/clinicFacilityTestManagementGrid";
import ClinicPhysicianGrid from "../components/clinicPortal/physician/clinicPhysicianGrid";
import ClinicUserGrid from "../components/clinicPortal/user/clinicUserGrid";
import ClinicNotificationTypeGrid from "../components/clinicPortal/notificationManagement/clinicNotificationTypeGrid";
import FacilityNotificationGrid from "../components/clinicPortal/notificationManagement/facilityNotificationGrid";
import ClinicSuperAdminSettings from "../components/clinicPortal/superAdminSettings";
import ClinicUserInfoGrid from "../components/clinicPortal/userInfo/clinicUserInfoGrid";
import ClinicMaintenanceGrid from "../components/clinicPortal/labManagement/clinicMaintenanceGrid";
import ClinicInventoryGrid from "../components/clinicPortal/labManagement/clinicInventoryManagementGrid";
import ClinicLabGrid from "../components/clinicPortal/labManagement/clinicLabGrid";
import ClinicQuantitativeTestGrid from "../components/clinicPortal/labTestManagement/clinicQuantitativeTestGrid";
import ClinicAnalyteGrid from "../components/clinicPortal/labTestManagement/clinicAnalyteGrid";

import ThemeProvider from "../theme/ThemeProvider";
import ClinicQualitativeTestGrid from "../components/clinicPortal/labTestManagement/clinicQualitativeTestGrid";

class ClinicPortalRouter extends Component {
    render(props) {
        return <ThemeProvider>
            <ClinicPortalLayout>
                <Switch>
                    <Route path="/clinic/" exact component={ClinicPortalLoginContainer} />

                    <Route path="/clinic/dashboard" exact component={ClinicDashboard} />

                    <Route path="/clinic/orders" exact component={ClinicOrderGrid} />
                    <Route path="/clinic/requisitions/:type" exact component={ClinicRequisitionGrid} />
                    <Route path="/clinic/requestSampleCancel" exact component={ClinicRequisitionNewGrid} />
                    <Route path="/clinic/resultLinks" exact component={ClinicResultLinksGrid} />
                    <Route path="/clinic/hl7Messages" exact component={ClinicHl7MessageGrid} />

                    <Route path="/clinic/facilityCommunications" exact component={ClinicFacilityCommunications} />
                    <Route path="/clinic/communication/:communicationId" exact component={ClinicCommunicationDetail} />

                    <Route path="/clinic/patients" exact component={ClinicPatientGrid} />
                    <Route path="/clinic/insurance" exact component={ClinicInsuranceGrid} />
                    <Route path="/clinic/patientsDuplicated" exact component={ClinicPatientDuplicatedGrid} />

                    <Route path="/clinic/invoices" exact component={ClinicInvoiceGrid} />
                    <Route path="/clinic/selfPayment" exact component={ClinicSelfPaymentGrid} />
                    <Route path="/clinic/insuranceProvider" exact component={ClinicInsuranceProviderGrid} />

                    <Route path="/clinic/audit" exact component={ClinicAuditGrid} />
                    <Route path="/clinic/reports/stateReports" exact component={ClinicStateReportsGrid} />
                    <Route path="/clinic/reports/dailyReports" exact component={ClinicDailyReportsGrid} />
                    <Route path="/clinic/reports/failedNotificationReports" exact component={ClinicFailedNotificationReportsGrid} />
                    <Route path="/clinic/reports/graphOrderTotals" exact component={ClinicOrderReportsGrid} />

                    <Route path="/clinic/vipPayments" exact component={ClinicVipPaymentGrid} />

                    <Route path="/clinic/company" exact component={ClinicCompanyGrid} />
                    <Route path="/clinic/facility" exact component={ClinicFacilityGrid} />
                    <Route path="/clinic/facilityTestManagement" exact component={ClinicFacilityTestManagementGrid} />
                    <Route path="/clinic/physician" exact component={ClinicPhysicianGrid} />
                    <Route path="/clinic/user" exact component={ClinicUserGrid} />

                    <Route path="/clinic/notificationType" exact component={ClinicNotificationTypeGrid} />
                    <Route path="/clinic/facilityNotificationManagement" exact component={FacilityNotificationGrid} />

                    <Route path="/clinic/superAdminSettings" exact component={ClinicSuperAdminSettings} />

                    <Route path="/clinic/userInfo" exact component={ClinicUserInfoGrid} />

                    <Route path="/clinic/maintenance" exact component={ClinicMaintenanceGrid} />
                    <Route path="/clinic/inventory" exact component={ClinicInventoryGrid} />
                    <Route path="/clinic/lab" exact component={ClinicLabGrid} />

                    <Route path="/clinic/quantitativeAnalyte" exact component={ClinicAnalyteGrid} />
                    <Route path="/clinic/quantitativeTest" exact component={ClinicQuantitativeTestGrid} />
                    <Route path="/clinic/qualitativeTest" exact component={ClinicQualitativeTestGrid} />
                </Switch>
            </ClinicPortalLayout>
        </ThemeProvider>
    }
}

export default ClinicPortalRouter;