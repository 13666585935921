import React, { Component } from "react";
import $ from "jquery";
import { Badge } from "@mui/material";
import {
    isUserLoggedIn,
    getUserRole,
    getUserDetails
} from "../../../services/common/util";
import { unreadCommunications } from "../../../services/clinicPortalServices/communicationService";
import { Link } from "react-router-dom";

class ClinicSidebar extends Component {
    constructor(props) {
        super(props);
        let userName = "";
        if (getUserDetails()) {
            const userDetails = JSON.parse(getUserDetails());
            userName = userDetails.user_name;
        }
        this.state = {
            subMenu: "",
            newCommunications: 0,
            userName: userName
        };
    }

    socketConnect = () => {
        if (window.socket) {
            window.socket.on("updateReadCommunication", (responseData) => {
                this.getUnreadCommunications();
            });
        }
    };

    componentDidMount() {
        this.getUnreadCommunications();
        setTimeout(() => {
            this.socketConnect();
        }, 500);

        $(".groupMenu a").on("click", function (e) {
            if ($(this).parent().hasClass("submenu")) {
                e.preventDefault();
            }
            if (!$(this).hasClass("subdrop")) {
                $("ul", $(this).parents("ul:first")).removeClass("active");
                $("a", $(this).parents("ul:first")).removeClass("subdrop");
                $(this).next("ul").addClass("active");
                $(this).addClass("subdrop");
            } else if ($(this).hasClass("subdrop")) {
                $(this).removeClass("subdrop");
                $(this).next("ul").removeClass("active");
            }
        });
        // $('#groupMenu ul.submenu').addClass('active').trigger('click');

        if (
            /requestsamplecancel$|orders$|resultlinks$|hl7messages$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "orders" });
        }
        if (this.props.location.pathname.includes("requisitions/")) {
            this.setState({ subMenu: "orders" });
        }
        if (
            /facilitycommunications$|patientcommunications$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "communication" });
        }
        if (
            /patients$|insurance$|patientsduplicated$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "patientmenu" });
        }
        if (
            /invoices$|insuranceprovider$|selfpayment$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "billing" });
        }
        if (
            /statereports$|dailyreports$|failednotificationreports$|graphordertotals$|audit$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "reports" });
        }

        if (
            /facility$|facilitytestmanagement$|physician$|user$|location$|company$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "facilitymenu" });
        }
        if (
            /notificationtype$|facilitynotificationmanagement$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "notificationmenu" });
        }
        if (
            /superadminsettings$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "adminmenu" });
        }
        if (
            /lab$|maintenance$|inventory$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "labmanagementmenu" });
        }
        if (
            /quantitativeanalyte$|quantitativetest$|qualitativetest$/.test(
                this.props.location.pathname.split("/").at(-1).toLowerCase()
            )
        ) {
            this.setState({ subMenu: "labtestmanagementmenu" });
        }
    }
    getUnreadCommunications = () => {
        if (isUserLoggedIn()) {
            unreadCommunications().then((res) => {
                if (res.data) {
                    this.setState({ newCommunications: res.data.length });
                }
            });
        }
    };
    render() {
        const { location } = this.props;
        let pathname = location.pathname;
        return (
            <div className="clinic-sidebar" id="sidebar">
                <nav className="clinicboard-menu">
                    <ul className="clinicMenu">
                        <>
                            {getUserRole() &&
                                /^superadmin|^admin/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "dashboard"
                                                ? "groupMenu active"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/dashboard">
                                            <i className="fas fa-columns mr-2"></i>
                                            <span className="menuLabel">
                                                Dashboard
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            {!/accountant/.test(
                                getUserRole().toLowerCase()
                            ) && (
                                <li className="groupMenu">
                                    <a
                                        href="#orders"
                                        className={
                                            this.state.subMenu === "orders"
                                                ? "subdrop"
                                                : ""
                                        }
                                    >
                                        <i className="fab fa-first-order mr-2"></i>
                                        <span className="menuLabel">
                                            Orders
                                        </span>
                                        <span className="menu-arrow">
                                            <i className="fas fa-chevron-right"></i>
                                        </span>
                                    </a>
                                    <ul
                                        className={
                                            this.state.subMenu === "orders"
                                                ? "submenu active"
                                                : "submenu"
                                        }
                                    >
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "orders"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/orders">
                                                Orders
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                pathname.includes(
                                                    "requisitions/"
                                                )
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/requisitions/view">
                                                Requisitions
                                            </Link>
                                        </li>
                                        {!/salesperson|salesadmin|facilityuser|facilityadmin/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "requestSampleCancel"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/requestSampleCancel">
                                                    Request & Cancel Samples
                                                </Link>
                                            </li>
                                        )}
                                        {getUserRole() &&
                                            /^superadmin|^admin|customerserviceuser/.test(
                                                getUserRole().toLowerCase()
                                            ) && (
                                                <li
                                                    className={
                                                        pathname
                                                            .split("/")
                                                            .at(-1) ===
                                                        "resultLinks"
                                                            ? "active"
                                                            : ""
                                                    }
                                                >
                                                    <Link to="/clinic/resultLinks">
                                                        Result Links
                                                    </Link>
                                                </li>
                                            )}
                                        {getUserRole() &&
                                            /^superadmin|^admin/.test(
                                                getUserRole().toLowerCase()
                                            ) && (
                                                <li
                                                    className={
                                                        pathname
                                                            .split("/")
                                                            .at(-1) ===
                                                        "hl7Messages"
                                                            ? "active"
                                                            : ""
                                                    }
                                                >
                                                    <Link to="/clinic/hl7Messages">
                                                        HL7 Messages
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </li>
                            )}
                            {getUserRole() &&
                                /^superadmin|^admin|facilityadmin|facilityuser|labtech/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "facilityCommunications"
                                                ? "groupMenu active"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/facilityCommunications">
                                            <Badge
                                                badgeContent={
                                                    this.state.newCommunications
                                                }
                                                color="secondary"
                                                className="mr-2"
                                                overlap="rectangular"
                                            >
                                                <i className="fas fa-comments"></i>
                                            </Badge>
                                            <span className="menuLabel">
                                                Communication
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            {!/salesperson|salesadmin|accountant/.test(
                                getUserRole().toLowerCase()
                            ) && (
                                <li className="groupMenu">
                                    <a
                                        href="#patientmenu"
                                        className={
                                            this.state.subMenu === "patientmenu"
                                                ? "subdrop"
                                                : ""
                                        }
                                    >
                                        <i className="fas fa-procedures mr-2"></i>
                                        <span className="menuLabel">
                                            Patient Menu
                                        </span>
                                        <span className="menu-arrow">
                                            <i className="fas fa-chevron-right"></i>
                                        </span>
                                    </a>
                                    <ul
                                        className={
                                            this.state.subMenu === "patientmenu"
                                                ? "submenu active"
                                                : "submenu"
                                        }
                                    >
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "patients"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/patients">
                                                Patients
                                            </Link>
                                        </li>
                                        {!/labtech/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) === "insurance"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/insurance">
                                                    Patient Insurance
                                                </Link>
                                            </li>
                                        )}
                                        {!/labtech|customerserviceuser/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "patientsDuplicated"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/patientsDuplicated">
                                                    Possible Patient Duplicates
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {getUserRole() &&
                                /facilityadmin/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "user"
                                                ? "active groupMenu"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/user">
                                            <i className="fas fa-user mr-2"></i>
                                            <span className="menuLabel">
                                                User
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            {getUserRole() &&
                                /facilityadmin|facilityuser/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "deliveryPickupOrder"
                                                ? "active groupMenu"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/deliveryPickupOrder">
                                            <i className="fas fa-ambulance mr-2"></i>
                                            <span className="menuLabel">
                                                Delivery & Pickup Orders
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            {getUserRole() &&
                                /^superadmin|^admin|^accountant/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li className="groupMenu">
                                        <a
                                            href="#billing"
                                            className={
                                                this.state.subMenu === "billing"
                                                    ? "subdrop"
                                                    : ""
                                            }
                                        >
                                            <i className="fas fa-file-invoice-dollar mr-2"></i>
                                            <span className="menuLabel">
                                                Billing
                                            </span>
                                            <span className="menu-arrow">
                                                <i className="fas fa-chevron-right"></i>
                                            </span>
                                        </a>
                                        <ul
                                            className={
                                                this.state.subMenu === "billing"
                                                    ? "submenu active"
                                                    : "submenu"
                                            }
                                        >
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) === "invoices"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/invoices">
                                                    Invoices
                                                </Link>
                                            </li>
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "selfPayment"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/selfPayment">
                                                    Self/Pay Payments
                                                </Link>
                                            </li>
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "insuranceProvider"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/insuranceProvider">
                                                    Insurance Provider
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                            {!/labtech|facilityadmin|facilityuser|accountant/.test(
                                getUserRole().toLowerCase()
                            ) && (
                                <li className="groupMenu">
                                    <a
                                        href="#reports"
                                        className={
                                            this.state.subMenu === "reports"
                                                ? "subdrop"
                                                : ""
                                        }
                                    >
                                        <i className="fas fa-file-medical-alt mr-2"></i>
                                        <span className="menuLabel">
                                            Audit & Reports
                                        </span>
                                        <span className="menu-arrow">
                                            <i className="fas fa-chevron-right"></i>
                                        </span>
                                    </a>
                                    <ul
                                        className={
                                            this.state.subMenu === "reports"
                                                ? "submenu active"
                                                : "submenu"
                                        }
                                    >
                                        {!/salesperson|salesadmin/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) === "audit"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/audit">
                                                    Audit Log
                                                </Link>
                                            </li>
                                        )}
                                        {!/salesperson|salesadmin/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "stateReports"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/reports/stateReports">
                                                    State Reports
                                                </Link>
                                            </li>
                                        )}
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "dailyReports"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/reports/dailyReports">
                                                End of Day Reports
                                            </Link>
                                        </li>
                                        {!/salesperson|salesadmin/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "failedNotificationReports"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/reports/failedNotificationReports">
                                                    Failed SMS/Email Reports
                                                </Link>
                                            </li>
                                        )}
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "graphOrderTotals"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/reports/graphOrderTotals">
                                                Order Totals Graph
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {!/labtech|facilityadmin|facilityuser|accountant/.test(
                                getUserRole().toLowerCase()
                            ) && (
                                <li className="groupMenu">
                                    <a
                                        href="#facilitymenu"
                                        className={
                                            this.state.subMenu ===
                                            "facilitymenu"
                                                ? "subdrop"
                                                : ""
                                        }
                                    >
                                        <i className="fas fa-clinic-medical mr-2"></i>
                                        <span className="menuLabel">
                                            Facility Management
                                        </span>
                                        <span className="menu-arrow">
                                            <i className="fas fa-chevron-right"></i>
                                        </span>
                                    </a>
                                    <ul
                                        className={
                                            this.state.subMenu ===
                                            "facilitymenu"
                                                ? "submenu active"
                                                : "submenu"
                                        }
                                    >
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "company"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/company">
                                                Company
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "facility"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/facility">
                                                Facility
                                            </Link>
                                        </li>
                                        {/superadmin|^admin/.test(
                                            getUserRole().toLowerCase()
                                        ) && (
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "facilityTestManagement"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/facilityTestManagement">
                                                    Patient Test Result Access
                                                </Link>
                                            </li>
                                        )}
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "physician"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/physician">
                                                Physician
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "user"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/user">User</Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {/superadmin|^admin/.test(
                                getUserRole().toLowerCase()
                            ) && (
                                <li className="groupMenu">
                                    <a
                                        href="#notificationmenu"
                                        className={
                                            this.state.subMenu ===
                                            "notificationmenu"
                                                ? "subdrop"
                                                : ""
                                        }
                                    >
                                        <i className="fas fa-bell mr-2"></i>
                                        <span className="menuLabel">
                                            Notification Management
                                        </span>
                                        <span className="menu-arrow">
                                            <i className="fas fa-chevron-right"></i>
                                        </span>
                                    </a>
                                    <ul
                                        className={
                                            this.state.subMenu ===
                                            "notificationmenu"
                                                ? "submenu active"
                                                : "submenu"
                                        }
                                    >
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "notificationType"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/notificationType">
                                                Notification Types
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                pathname.split("/").at(-1) ===
                                                "facilityNotificationManagement"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/clinic/facilityNotificationManagement">
                                                Facility Notification Management
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            {getUserRole() &&
                                /facilityadmin/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "facilityNotificationManagement"
                                                ? "active groupMenu"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/facilityNotificationManagement">
                                            <i className="fas fa-bell mr-2"></i>
                                            <span className="menuLabel">
                                                Notification Management
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            {getUserRole() &&
                                /facilityadmin/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "facilityTestManagement"
                                                ? "active groupMenu"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/facilityTestManagement">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                className="mr-2"
                                                viewBox="0 0 576 512"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M48 0C21.5 0 0 21.5 0 48V256H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H0v64H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H0v80c0 26.5 21.5 48 48 48H265.9c-6.3-10.2-9.9-22.2-9.9-35.1c0-46.9 25.8-87.8 64-109.2V271.8 48c0-26.5-21.5-48-48-48H48zM152 64h16c8.8 0 16 7.2 16 16v24h24c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H184v24c0 8.8-7.2 16-16 16H152c-8.8 0-16-7.2-16-16V152H112c-8.8 0-16-7.2-16-16V120c0-8.8 7.2-16 16-16h24V80c0-8.8 7.2-16 16-16zM512 272c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM288 477.1c0 19.3 15.6 34.9 34.9 34.9H541.1c19.3 0 34.9-15.6 34.9-34.9c0-51.4-41.7-93.1-93.1-93.1H381.1c-51.4 0-93.1 41.7-93.1 93.1z"
                                                />
                                            </svg>
                                            <span className="menuLabel">
                                                Patient Test Result Access
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            {getUserRole() &&
                                /^superadmin|^admin/.test(
                                    getUserRole().toLowerCase()
                                ) &&
                                (this.state.userName === "creid" ||
                                    this.state.userName ===
                                        "tai") && (
                                    <li className="groupMenu">
                                        <a
                                            href="#adminmenu"
                                            className={
                                                this.state.subMenu ===
                                                "adminmenu"
                                                    ? "subdrop"
                                                    : ""
                                            }
                                        >
                                            <i className="fas fa-user-lock mr-2"></i>
                                            <span className="menuLabel">
                                                Admin
                                            </span>
                                            <span className="menu-arrow">
                                                <i className="fas fa-chevron-right"></i>
                                            </span>
                                        </a>
                                        <ul
                                            className={
                                                this.state.subMenu ===
                                                "adminmenu"
                                                    ? "submenu active"
                                                    : "submenu"
                                            }
                                        >
                                            <li
                                                className={
                                                    pathname
                                                        .split("/")
                                                        .at(-1) ===
                                                    "superAdminSettings"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/clinic/superAdminSettings">
                                                    Super Admin Settings
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                            {getUserRole() &&
                                /superadmin|^admin/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li
                                        className={
                                            pathname.split("/").at(-1) ===
                                            "userInfo"
                                                ? "active groupMenu"
                                                : "groupMenu"
                                        }
                                    >
                                        <Link to="/clinic/userInfo">
                                            <i
                                                className="fas fa-info-circle mr-2"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="menuLabel">
                                                User Info Management
                                            </span>
                                        </Link>
                                    </li>
                                )}

                            {getUserRole() &&
                                /^superadmin|^admin|^labtech/.test(
                                    getUserRole().toLowerCase()
                                ) && (
                                    <li className="groupMenu">
                                        <a
                                            href="#labmanagementmenu"
                                            className={
                                                [
                                                    "labmanagementmenu",
                                                    "labtestmanagementmenu"
                                                ].includes(this.state.subMenu)
                                                    ? "subdrop"
                                                    : ""
                                            }
                                        >
                                            <i className="fas fa-flask mr-2"></i>
                                            <span className="menuLabel">
                                                Lab Hub
                                            </span>
                                            <span className="menu-arrow">
                                                <i className="fas fa-chevron-right"></i>
                                            </span>
                                        </a>
                                        <ul
                                            className={
                                                [
                                                    "labmanagementmenu",
                                                    "labtestmanagementmenu"
                                                ].includes(this.state.subMenu)
                                                    ? "submenu active"
                                                    : "submenu"
                                            }
                                        >
                                            <li className="groupMenu">
                                                <a
                                                    href="#labmanagementmenu"
                                                    className={
                                                        this.state.subMenu ===
                                                        "labmanagementmenu"
                                                            ? "subdrop"
                                                            : ""
                                                    }
                                                >
                                                    <i className="fas fa-microscope mr-2"></i>
                                                    <span className="menuLabel">
                                                        Lab Management
                                                    </span>
                                                    <span className="menu-arrow">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </span>
                                                </a>
                                                <ul
                                                    className={
                                                        this.state.subMenu ===
                                                        "labmanagementmenu"
                                                            ? "submenu active"
                                                            : "submenu"
                                                    }
                                                >
                                                    {getUserRole() &&
                                                        /^superadmin|^admin/.test(
                                                            getUserRole().toLowerCase()
                                                        ) && (
                                                            <li
                                                                className={
                                                                    pathname
                                                                        .split(
                                                                            "/"
                                                                        )
                                                                        .at(
                                                                            -1
                                                                        ) ===
                                                                    "lab"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                            >
                                                                <Link to="/clinic/lab">
                                                                    Lab PDF
                                                                    Management
                                                                </Link>
                                                            </li>
                                                        )}
                                                    <li
                                                        className={
                                                            pathname
                                                                .split("/")
                                                                .at(-1) ===
                                                            "maintenance"
                                                                ? "active"
                                                                : ""
                                                        }
                                                    >
                                                        <Link to="/clinic/maintenance">
                                                            Maintenance
                                                            Management
                                                        </Link>
                                                    </li>
                                                    <li
                                                        className={
                                                            pathname
                                                                .split("/")
                                                                .at(-1) ===
                                                            "inventory"
                                                                ? "active"
                                                                : ""
                                                        }
                                                    >
                                                        <Link to="/clinic/inventory">
                                                            Inventory Management
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            {getUserRole() &&
                                                /^superadmin|^admin/.test(
                                                    getUserRole().toLowerCase()
                                                ) && (
                                                    <li className="groupMenu">
                                                        <a
                                                            href="#labtestmanagementmenu"
                                                            className={
                                                                this.state
                                                                    .subMenu ===
                                                                "labtestmanagementmenu"
                                                                    ? "subdrop"
                                                                    : ""
                                                            }
                                                        >
                                                            <i className="fas fa-vial mr-2"></i>
                                                            <span className="menuLabel">
                                                                Lab Test
                                                                Management
                                                            </span>
                                                            <span className="menu-arrow">
                                                                <i className="fas fa-chevron-right"></i>
                                                            </span>
                                                        </a>
                                                        <ul
                                                            className={
                                                                this.state
                                                                    .subMenu ===
                                                                "labtestmanagementmenu"
                                                                    ? "submenu active"
                                                                    : "submenu"
                                                            }
                                                        >
                                                            <li className="groupMenu">
                                                                <a
                                                                    href="#labtestmanagementmenu"
                                                                    className={
                                                                        [
                                                                            "quantitativeAnalyte",
                                                                            "quantitativeTest"
                                                                        ].includes(
                                                                            pathname
                                                                                .split(
                                                                                    "/"
                                                                                )
                                                                                .at(
                                                                                    -1
                                                                                )
                                                                        )
                                                                            ? "subdrop"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <span className="menuLabel">
                                                                        Quantitative
                                                                        Tests
                                                                    </span>
                                                                    <span className="menu-arrow">
                                                                        <i className="fas fa-chevron-right"></i>
                                                                    </span>
                                                                </a>
                                                                <ul
                                                                    className={
                                                                        [
                                                                            "quantitativeAnalyte",
                                                                            "quantitativeTest"
                                                                        ].includes(
                                                                            pathname
                                                                                .split(
                                                                                    "/"
                                                                                )
                                                                                .at(
                                                                                    -1
                                                                                )
                                                                        )
                                                                            ? "submenu active"
                                                                            : "submenu"
                                                                    }
                                                                >
                                                                    <li
                                                                        className={
                                                                            pathname
                                                                                .split(
                                                                                    "/"
                                                                                )
                                                                                .at(
                                                                                    -1
                                                                                ) ===
                                                                            "quantitativeAnalyte"
                                                                                ? "active"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <Link to="/clinic/quantitativeAnalyte">
                                                                            Analytes
                                                                            Management
                                                                        </Link>
                                                                    </li>
                                                                    <li
                                                                        className={
                                                                            pathname
                                                                                .split(
                                                                                    "/"
                                                                                )
                                                                                .at(
                                                                                    -1
                                                                                ) ===
                                                                            "quantitativeTest"
                                                                                ? "active"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <Link to="/clinic/quantitativeTest">
                                                                            Test,
                                                                            Panel
                                                                            Management
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li
                                                                className={
                                                                    pathname
                                                                        .split(
                                                                            "/"
                                                                        )
                                                                        .at(
                                                                            -1
                                                                        ) ===
                                                                    "qualitativeTest"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                            >
                                                                <Link to="/clinic/qualitativeTest">
                                                                    Qualitative
                                                                    Tests
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                )}
                                            <li>
                                                <Link to="/lims/accessioning">
                                                    <i className="fas fa-flask mr-2"></i>
                                                    <span className="menuLabel">
                                                        Plate Management
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                        </>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default ClinicSidebar;
