import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
    updateTest,
    createTest,
    getTestById,
    getAllTestsForManagementPage
} from "../../../../services/clinicPortalServices/testService";
import Swal from "sweetalert2";
import toastr from "toastr";
import { testMachines } from "../../../../services/common/optionsData";
import {
    FormControl,
    Checkbox,
    MenuItem,
    Select,
    InputLabel,
    TextField
} from "@mui/material";
import { getUserDetails } from "../../../../services/common/util";
import {
    specimenTypes,
    sampleTypes
} from "../../../../services/common/optionsData";

export default class TestDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            testId: this.props && this.props.testId ? this.props.testId : "",
            name: "",
            method_name: "",
            form_dropdown_priority: "",
            code: "",
            order_code_description: "",
            collection_requirements: "",
            specimen_transport_temperature: "",
            machine: [],
            loinc_code: "",
            cptCodes: [],
            results: [],
            specimenType: "",
            sampleType: "",
            symptoms: [],
            targets: [],
            diagnosis_codes: [],
            isActive: true,
            description: "",
            department: "",
            turnaroundTime: "",
            collectionInstructions: "",
            storageInstructions: "",
            reportingTemplate: "",
            reflexTestingRules: "",
            notes: "",
            resultPDFDescriptionNotes: "",
            resultKeyInfoArray: [],
            isPanel: false,
            tests: [],
            testIds: [],
            errors: []
        };

        this.userName = "";
        if (getUserDetails()) {
            this.userName = JSON.parse(getUserDetails()).user_name;
        }
        getAllTestsForManagementPage({
            isQuantitativeTest: false,
            isPanel: false
        }).then((res) => {
            this.setState({ tests: res.data });
        });
    }

    componentDidMount() {
        if (this.state.testId !== "") {
            this.loadDetails();
        }
    }

    loadDetails = () => {
        getTestById(this.state.testId).then((response) => {
            let details = response.data;

            this.setState({
                name: details.name ? details.name : "",
                method_name: details.method_name ? details.method_name : "",
                form_dropdown_priority: details.form_dropdown_priority,
                order_code_description: details.order_code_description
                    ? details.order_code_description
                    : "",
                collection_requirements: details.collection_requirements
                    ? details.collection_requirements
                    : "",
                specimen_transport_temperature:
                    details.specimen_transport_temperature
                        ? details.specimen_transport_temperature
                        : "",
                code: details.code ? details.code : "",
                machine: details.machine ? details.machine : [],
                isPanel: details.isPanel ? true : false,
                testIds:
                    details.testIds && details.testIds.length
                        ? details.testIds
                        : [],
                loinc_code: details.loinc_code ? details.loinc_code : "",
                cptCodes: details.cptCodes ? details.cptCodes : [],
                results:
                    details.results && details.results.length
                        ? details.results
                        : [],
                specimenType: details.specimenType ? details.specimenType : "",
                sampleType: details.sampleType ? details.sampleType : "",
                symptoms:
                    details.symptoms && details.symptoms.length
                        ? details.symptoms
                        : [],
                targets:
                    details.targets && details.targets.length
                        ? details.targets
                        : [],
                diagnosis_codes:
                    details.diagnosis_codes && details.diagnosis_codes.length
                        ? details.diagnosis_codes
                        : [],
                isActive: details.isActive ? true : false,
                description: details.description ? details.description : "",
                department: details.department ? details.department : "",
                turnaroundTime: details.turnaroundTime
                    ? details.turnaroundTime
                    : "",
                collectionInstructions: details.collectionInstructions
                    ? details.collectionInstructions
                    : "",
                storageInstructions: details.storageInstructions
                    ? details.storageInstructions
                    : "",
                reportingTemplate: details.reportingTemplate
                    ? details.reportingTemplate
                    : "",
                reflexTestingRules: details.reflexTestingRules
                    ? details.reflexTestingRules
                    : "",
                notes: details.notes ? details.notes : "",
                resultPDFDescriptionNotes: details.resultPDFDescriptionNotes
                    ? details.resultPDFDescriptionNotes
                    : "",
                resultKeyInfoArray:
                    details.resultKeyInfoArray &&
                    details.resultKeyInfoArray.length
                        ? details.resultKeyInfoArray
                        : []
            });
        });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "specimenType") {
            this.setState({ sampleType: "" });
        }
    };

    addArrayItem = (type) => {
        if (type) {
            let state = this.state;
            switch (type) {
                case "results":
                    state.results.push("");
                    break;
                case "cptCodes":
                    state.cptCodes.push("");
                    break;
                case "symptoms":
                    state.symptoms.push("");
                    break;
                case "targets":
                    state.targets.push("");
                    break;
                case "diagnosis_codes":
                    state.diagnosis_codes.push({
                        code: "",
                        text: ""
                    });
                    break;
                case "resultKeyInfoArray":
                    state.resultKeyInfoArray.push({
                        isNormalResult: false,
                        resultKey: "",
                        resultText: ""
                    });
                    break;
                default:
                    break;
            }
            this.setState(state);
        }
    };

    updateArrayItem = (value, index, type, subProperty) => {
        if (type === "results") {
            let results = this.state.results;
            this.setState({
                results: results.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        } else if (type === "cptCodes") {
            let cptCodes = this.state.cptCodes;
            this.setState({
                cptCodes: cptCodes.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        } else if (type === "symptoms") {
            let symptoms = this.state.symptoms;
            this.setState({
                symptoms: symptoms.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        } else if (type === "targets") {
            let targets = this.state.targets;
            this.setState({
                targets: targets.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        } else if (type === "diagnosis_codes") {
            let diagnosis_codes = this.state.diagnosis_codes;
            this.setState({
                diagnosis_codes: diagnosis_codes.map((item, i) => {
                    if (i === index) {
                        item[subProperty] = value;
                    }
                    return item;
                })
            });
        } else if (type === "resultKeyInfoArray") {
            let resultKeyInfoArray = this.state.resultKeyInfoArray;
            this.setState({
                resultKeyInfoArray: resultKeyInfoArray.map((item, i) => {
                    if (i === index) {
                        item[subProperty] = value;
                    }
                    return item;
                })
            });
        }
    };

    removeArrayItem = (type, index) => {
        if (type) {
            let state = this.state;
            switch (type) {
                case "results":
                    state.results.splice(index, 1);
                    break;
                case "cptCodes":
                    state.cptCodes.splice(index, 1);
                    break;
                case "symptoms":
                    state.symptoms.splice(index, 1);
                    break;
                case "targets":
                    state.targets.splice(index, 1);
                    break;
                case "diagnosis_codes":
                    state.diagnosis_codes.splice(index, 1);
                    break;
                case "resultKeyInfoArray":
                    state.resultKeyInfoArray.splice(index, 1);
                    break;
                default:
                    break;
            }
            this.setState(state);
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    saveChanges = () => {
        let errors = [];

        if (this.state.name === "") {
            errors.push("name");
        }
        if (this.state.specimenType === "") {
            errors.push("specimenType");
        }
        if (this.state.sampleType === "") {
            errors.push("sampleType");
        }
        if (this.state.method_name === "") {
            errors.push("method_name");
        }
        if (this.state.code === "") {
            errors.push("code");
        }
        if (!this.state.testIds.length && this.state.isPanel) {
            errors.push("testIds");
        }

        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let details = {
            name: this.state.name,
            method_name: this.state.method_name,
            form_dropdown_priority: this.state.form_dropdown_priority,
            order_code_description: this.state.order_code_description,
            code: this.state.code,
            collection_requirements: this.state.collection_requirements,
            specimen_transport_temperature:
                this.state.specimen_transport_temperature,
            machine: this.state.machine,
            isPanel: this.state.isPanel,
            testIds: this.state.testIds,
            loinc_code: this.state.loinc_code,
            cptCodes: this.state.cptCodes,
            results: this.state.results,
            specimenType: this.state.specimenType,
            sampleType: this.state.sampleType,
            symptoms: this.state.symptoms,
            targets: this.state.targets,
            diagnosis_codes: this.state.diagnosis_codes,
            isActive: this.state.isActive,
            description: this.state.description,
            department: this.state.department,
            turnaroundTime: this.state.turnaroundTime,
            collectionInstructions: this.state.collectionInstructions,
            storageInstructions: this.state.storageInstructions,
            reportingTemplate: this.state.reportingTemplate,
            reflexTestingRules: this.state.reflexTestingRules,
            notes: this.state.notes,
            resultPDFDescriptionNotes: this.state.resultPDFDescriptionNotes,
            resultKeyInfoArray: this.state.resultKeyInfoArray,
            isQuantitativeTest: false
        };
        if (this.state.testId !== "") {
            details._id = this.state.testId;
            updateTest(details).then((response) => {
                if (response.RESULT === "ERROR") {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        text: response.message,
                        icon: "error",
                        confirmButtonText: "Ok"
                    });
                } else {
                    this.props.context.componentParent.loadGridData();
                    this.props.handleClose();
                    toastr.success(response.message);
                }
            });
        } else {
            createTest(details).then((response) => {
                if (response.RESULT === "ERROR") {
                    Swal.fire({
                        customClass: {
                            container:
                                window.localStorage.getItem("appTheme") ===
                                    "Dark" &&
                                /clinic|lims/.test(
                                    window.location.pathname.split("/")[1]
                                ) &&
                                "dark-swal"
                        },
                        text: response.message,
                        icon: "error",
                        confirmButtonText: "Ok"
                    });
                } else {
                    this.props.context.componentParent.loadGridData();
                    this.props.handleClose();
                    toastr.success(response.message);
                }
            });
        }
    };

    handleSubObjectItem = (event, subType, subIndex, type) => {
        if (type === "diagnosis_codes") {
            let value = event.target.value;
            let diagnosis_codes = this.state.diagnosis_codes;
            diagnosis_codes.map((item, i) => {
                if (i === subIndex) {
                    item[subType] = value;
                }
                return null;
            });
            this.setState({ diagnosis_codes: diagnosis_codes });
        } else if (type === "resultKeyInfoArray") {
            let value = event.target.value;
            let resultKeyInfoArray = this.state.resultKeyInfoArray;
            resultKeyInfoArray.map((item, i) => {
                if (i === subIndex) {
                    item[subType] = value;
                }
                return null;
            });
            this.setState({ resultKeyInfoArray: resultKeyInfoArray });
        }
    };

    toggleState = (type) => {
        if (type === "isActive") {
            this.setState({
                isActive: !this.state.isActive
            });
        } else if (type === "isPanel") {
            this.setState({
                isPanel: !this.state.isPanel
            });
        }
    };

    render() {
        return (
            <div>
                <div className="row form-row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <TextField
                                label="Name *"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("name")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                select
                                label="Specimen Type *"
                                name="specimenType"
                                value={this.state.specimenType}
                                onChange={this.handleChange}
                            >
                                {specimenTypes.map((specimenType) => {
                                    return (
                                        <MenuItem
                                            value={specimenType}
                                            key={specimenType}
                                        >
                                            {specimenType}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            <div
                                className={
                                    this.hasError("specimenType")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                select
                                label="Sample Type *"
                                name="sampleType"
                                value={this.state.sampleType}
                                onChange={this.handleChange}
                            >
                                {sampleTypes[this.state.specimenType] &&
                                    sampleTypes[this.state.specimenType]
                                        .length > 0 &&
                                    sampleTypes[this.state.specimenType].map(
                                        (sampleType) => {
                                            return (
                                                <MenuItem
                                                    value={sampleType}
                                                    key={sampleType}
                                                >
                                                    {sampleType}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </TextField>
                            <div
                                className={
                                    this.hasError("sampleType")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Code *"
                                name="code"
                                value={this.state.code}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("code")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Method Name *"
                                name="method_name"
                                value={this.state.method_name}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("method_name")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <MenuItem
                                onClick={() => this.toggleState("isPanel")}
                            >
                                <Checkbox checked={this.state.isPanel} />
                                <div style={{ whiteSpace: "break-spaces" }}>
                                    Is Panel?
                                </div>
                            </MenuItem>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        {this.state.isPanel && (
                            <div className="form-group">
                                <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="machine-label">
                                        Tests for Panel
                                    </InputLabel>
                                    <Select
                                        labelId="machine-label"
                                        id="machine-select"
                                        value={this.state.testIds}
                                        onChange={this.handleChange}
                                        label="Tests for Panel"
                                        className={
                                            this.hasError("testIds")
                                                ? "select is-invalid"
                                                : ""
                                        }
                                        name="testIds"
                                        multiple
                                        style={{ paddingBottom: "5px" }}
                                        renderValue={(selected) => {
                                            let displayStrings = [];
                                            this.state.tests.map((test) => {
                                                if (
                                                    selected.includes(test._id)
                                                ) {
                                                    displayStrings.push(
                                                        test.name
                                                    );
                                                }
                                                return null;
                                            });
                                            return displayStrings.join(", ");
                                        }}
                                    >
                                        {this.state.tests.map((test, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={test._id}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            this.state.testIds.includes(
                                                                test._id
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {test.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <div
                                    className={
                                        this.hasError("testIds")
                                            ? "inline-errormsg"
                                            : "hidden"
                                    }
                                >
                                    <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                    >
                                        &nbsp;This field is required.
                                    </i>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Form Dropdown Priority"
                                name="form_dropdown_priority"
                                type="number"
                                value={this.state.form_dropdown_priority}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("form_dropdown_priority")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="LOINC Code"
                                name="loinc_code"
                                value={this.state.loinc_code}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("loinc_code")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="machine-label">
                                    Machine
                                </InputLabel>
                                <Select
                                    labelId="machine-label"
                                    id="machine-select"
                                    value={this.state.machine}
                                    onChange={this.handleChange}
                                    label="Machine"
                                    className={
                                        this.hasError("machine")
                                            ? "select is-invalid"
                                            : ""
                                    }
                                    name="machine"
                                    multiple
                                    style={{ paddingBottom: "5px" }}
                                    renderValue={(selected) => {
                                        let displayStrings = [];
                                        Object.entries(testMachines).map(
                                            ([key, value]) => {
                                                if (selected.includes(key)) {
                                                    displayStrings.push(value);
                                                }
                                                return null;
                                            }
                                        );
                                        return displayStrings.join(", ");
                                    }}
                                >
                                    {Object.entries(testMachines).map(
                                        ([key, value]) => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    <Checkbox
                                                        checked={
                                                            this.state.machine.includes(
                                                                key
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {value}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            <div
                                className={
                                    this.hasError("machine")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group mt-2">
                            <MenuItem
                                onClick={() => this.toggleState("isActive")}
                            >
                                <Checkbox checked={this.state.isActive} />
                                <div style={{ whiteSpace: "break-spaces" }}>
                                    Is Active
                                </div>
                            </MenuItem>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Order Code Description"
                                name="order_code_description"
                                value={this.state.order_code_description}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Collection Requirements and Patient Prep Information"
                                name="collection_requirements"
                                value={this.state.collection_requirements}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Specimen Transport Temperature"
                                name="specimen_transport_temperature"
                                value={
                                    this.state.specimen_transport_temperature
                                }
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                CPT Codes{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("cptCodes")
                                    }
                                ></i>
                            </label>
                            {this.state.cptCodes.map((cptCode, index) => {
                                return (
                                    <div className="d-flex align-items-center mt-2">
                                        <TextField
                                            key={index}
                                            value={cptCode}
                                            onChange={(e) =>
                                                this.updateArrayItem(
                                                    e.target.value,
                                                    index,
                                                    "cptCodes"
                                                )
                                            }
                                        />
                                        <i
                                            className="fa fa-times-circle btn"
                                            onClick={() =>
                                                this.removeArrayItem(
                                                    "cptCodes",
                                                    index
                                                )
                                            }
                                        ></i>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                Diagnosis Codes{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("diagnosis_codes")
                                    }
                                ></i>
                            </label>
                            <ul>
                                {this.state.diagnosis_codes &&
                                    this.state.diagnosis_codes.length > 0 &&
                                    this.state.diagnosis_codes.map(
                                        (diagnosis_code, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="d-flex align-items-center mt-2">
                                                        <TextField
                                                            label="Code"
                                                            value={
                                                                diagnosis_code.code
                                                            }
                                                            onChange={(e) =>
                                                                this.handleSubObjectItem(
                                                                    e,
                                                                    "code",
                                                                    index,
                                                                    "diagnosis_codes"
                                                                )
                                                            }
                                                            sx={{
                                                                width: "30%",
                                                                marginRight:
                                                                    "10px"
                                                            }}
                                                        />
                                                        <TextField
                                                            label="Text"
                                                            value={
                                                                diagnosis_code.text
                                                            }
                                                            onChange={(e) =>
                                                                this.handleSubObjectItem(
                                                                    e,
                                                                    "text",
                                                                    index,
                                                                    "diagnosis_codes"
                                                                )
                                                            }
                                                            sx={{
                                                                width: "62%"
                                                            }}
                                                        />
                                                        <i
                                                            className="fa fa-times-circle btn"
                                                            onClick={() =>
                                                                this.removeArrayItem(
                                                                    "diagnosis_codes",
                                                                    index
                                                                )
                                                            }
                                                        ></i>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    )}
                            </ul>
                            <div
                                className={
                                    this.hasError("diagnosis_codes")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                Results{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() => this.addArrayItem("results")}
                                ></i>
                            </label>
                            {this.state.results.map((result, index) => {
                                return (
                                    <div className="d-flex align-items-center mt-2">
                                        <TextField
                                            key={index}
                                            value={result}
                                            onChange={(e) =>
                                                this.updateArrayItem(
                                                    e.target.value,
                                                    index,
                                                    "results"
                                                )
                                            }
                                        />
                                        <i
                                            className="fa fa-times-circle btn"
                                            onClick={() =>
                                                this.removeArrayItem(
                                                    "results",
                                                    index
                                                )
                                            }
                                        ></i>
                                    </div>
                                );
                            })}
                            <div
                                className={
                                    this.hasError("results")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                Symptoms{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("symptoms")
                                    }
                                ></i>
                            </label>
                            {this.state.symptoms.map((symptom, index) => {
                                return (
                                    <div className="d-flex align-items-center mt-2">
                                        <TextField
                                            key={index}
                                            value={symptom}
                                            onChange={(e) =>
                                                this.updateArrayItem(
                                                    e.target.value,
                                                    index,
                                                    "symptoms"
                                                )
                                            }
                                        />
                                        <i
                                            className="fa fa-times-circle btn"
                                            onClick={() =>
                                                this.removeArrayItem(
                                                    "symptoms",
                                                    index
                                                )
                                            }
                                        ></i>
                                    </div>
                                );
                            })}
                            <div
                                className={
                                    this.hasError("symptoms")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                Targets{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() => this.addArrayItem("targets")}
                                ></i>
                            </label>
                            {this.state.targets.map((target, index) => {
                                return (
                                    <div className="d-flex align-items-center mt-2">
                                        <TextField
                                            key={index}
                                            value={target}
                                            onChange={(e) =>
                                                this.updateArrayItem(
                                                    e.target.value,
                                                    index,
                                                    "targets"
                                                )
                                            }
                                        />
                                        <i
                                            className="fa fa-times-circle btn"
                                            onClick={() =>
                                                this.removeArrayItem(
                                                    "targets",
                                                    index
                                                )
                                            }
                                        ></i>
                                    </div>
                                );
                            })}
                            <div
                                className={
                                    this.hasError("targets")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group mt-3">
                            <TextField
                                label="Description"
                                name="description"
                                value={this.state.description}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Department"
                                name="department"
                                value={this.state.department}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Turnaround Time"
                                name="turnaroundTime"
                                value={this.state.turnaroundTime}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Collection Instructions"
                                name="collectionInstructions"
                                value={this.state.collectionInstructions}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Storage Instructions"
                                name="storageInstructions"
                                value={this.state.storageInstructions}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Reporting Template"
                                name="reportingTemplate"
                                value={this.state.reportingTemplate}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Reflex Testing Rules"
                                name="reflexTestingRules"
                                select
                                value={this.state.reflexTestingRules}
                                onChange={this.handleChange}
                            >
                                <MenuItem value="Release Regardless of Result">
                                    Release Regardless of Result
                                </MenuItem>
                                <MenuItem value="If inconclusive send back to accessioning">
                                    If inconclusive send back to accessioning
                                </MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                Result Key Info{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("resultKeyInfoArray")
                                    }
                                ></i>{" "}
                                (Select checkbox for normal result)
                            </label>
                            <ul>
                                {this.state.resultKeyInfoArray &&
                                    this.state.resultKeyInfoArray.length > 0 &&
                                    this.state.resultKeyInfoArray.map(
                                        (resultKeyInfoItem, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="d-flex align-items-center mt-2">
                                                        <TextField
                                                            label="Result Key"
                                                            value={
                                                                resultKeyInfoItem.resultKey
                                                            }
                                                            onChange={(e) =>
                                                                this.handleSubObjectItem(
                                                                    e,
                                                                    "resultKey",
                                                                    index,
                                                                    "resultKeyInfoArray"
                                                                )
                                                            }
                                                            sx={{
                                                                width: "30%",
                                                                marginRight:
                                                                    "10px"
                                                            }}
                                                        />
                                                        <TextField
                                                            label="Result Text"
                                                            value={
                                                                resultKeyInfoItem.resultText
                                                            }
                                                            onChange={(e) =>
                                                                this.handleSubObjectItem(
                                                                    e,
                                                                    "resultText",
                                                                    index,
                                                                    "resultKeyInfoArray"
                                                                )
                                                            }
                                                            sx={{
                                                                width: "55%"
                                                            }}
                                                        />
                                                        <Checkbox
                                                            checked={
                                                                resultKeyInfoItem.isNormalResult
                                                            }
                                                            className="mx-1"
                                                            onClick={() =>
                                                                this.handleSubObjectItem(
                                                                    {
                                                                        target: {
                                                                            name: "isNormalResult",
                                                                            value: !resultKeyInfoItem.isNormalResult
                                                                        }
                                                                    },
                                                                    "isNormalResult",
                                                                    index,
                                                                    "resultKeyInfoArray"
                                                                )
                                                            }
                                                        />
                                                        <i
                                                            className="fa fa-times-circle btn"
                                                            onClick={() =>
                                                                this.removeArrayItem(
                                                                    "resultKeyInfoArray",
                                                                    index
                                                                )
                                                            }
                                                        ></i>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    )}
                            </ul>
                            <div
                                className={
                                    this.hasError("resultKeyInfoArray")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <TextField
                                label="Result PDF Notes"
                                name="resultPDFDescriptionNotes"
                                multiline
                                rows={5}
                                value={this.state.resultPDFDescriptionNotes}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Notes"
                                name="notes"
                                multiline
                                rows={3}
                                value={this.state.notes}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12"
                        style={{
                            paddingTop: "10px",
                            borderTop: "1px solid rgba(0,0,0,.2"
                        }}
                    >
                        <Button
                            style={{ float: "right", marginLeft: "10px" }}
                            variant="primary"
                            onClick={this.saveChanges}
                        >
                            Save Changes
                        </Button>
                        <Button
                            style={{ float: "right" }}
                            variant="secondary"
                            onClick={this.props.handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
